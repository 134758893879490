import withStyle from "@/middleware/withStyle";
import clsx from "clsx";
import PbInput from "../pbInput/pbInput";

/**
 * Represents the props for the PbTimeInput component.
 */
export interface PbTimeInputProps {
  /**
   * The controlled value for the time input.
   */
  value?: string;

  /**
   * The label for the time input.
   */
  label?: string;

  /**
   * The callback function called when the value of the time input changes.
   * @param value - The new value of the time input.
   */
  onChange: (value: string) => void;

  /**
   * Indicates whether an error state should be displayed.
   */
  error?: boolean;

  /**
   * disables the time input and renders a text disabled text input instead
   */
  disabled?: boolean;

  /**
   * placeholder that is displayed on the disabled input
   */
  disabledPlaceholder?: string;

  /**
   * Toggles full width
   */
  fullWidth?: boolean;

  /**
   * Toggles usage of CmsThemeProvider and sets a class
   * @see withStyle
   */
  withCmsStyle?: boolean;

  /**
   * Toggles usage of PublicThemeProvider and sets a class
   */
  withPublicStyle?: boolean;
}

/**
 * PbTimeInput component represents an input field for time.
 * @param props - The component properties.
 * @returns A JSX element representing the PbTimeInput.
 */
const PbTimeInput = (props: PbTimeInputProps) => {
  const onTimeChange = (event: any) => {
    props.onChange(event.target.value);
  };

  const inputType =
    props.disabled && props.disabledPlaceholder ? "text" : "time";

  return (
    <div
      className={clsx(
        "time-input-wrapper",
        props.withCmsStyle && "with-cms-style",
        props.withPublicStyle && "with-public-style"
      )}
      style={{ width: props.fullWidth ? "100%" : "auto" }}
    >
      {props.label && (
        <>
          <label>{props.label}</label>
          <br />
        </>
      )}
      <PbInput
        defaultValue={
          props.disabled && props.disabledPlaceholder ? "" : props.value
        }
        onChange={onTimeChange}
        withCmsStyle
        type={inputType}
        disabled={props.disabled}
        fullWidth
        error={props.error}
        placeholder={props.disabled ? props.disabledPlaceholder : ""}
      />
    </div>
  );
};

export default withStyle(PbTimeInput);
