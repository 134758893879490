import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbDateInput from "@/components/input/pbDateInput/pbDateInput";
import PbDropdown from "@/components/input/pbDropdown/pbDropdown";
import PbInput from "@/components/input/pbInput/pbInput";
import PbRichTextEditor from "@/components/input/pbRichTextEditor/pbRichTextEditor";
import PbTimeInput from "@/components/input/pbTimeInput/pbTimeInput";
import PbRichTextView from "@/components/pbRichTextView/pbRichTextView";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import {
  addToArrayAction,
  moveInArrayAction,
  removeFromArrayAction,
  updateAttributeAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import clsx from "clsx";

import { useAppDispatch, useAppSelector } from "@/store/store";
import {
  CmsPropertyAttribute,
  CmsPropertyAttributeGroup,
  CmsPropertyRelationValue,
  CmsPropertyValues,
} from "@/types/strapi";
import {
  DEBOUNCE_DEFAULT_TIME,
  PROPERTY_ATTRIBUTE_BOOLEAN,
  PROPERTY_ATTRIBUTE_DATAPROVIDER,
  PROPERTY_ATTRIBUTE_DATE,
  PROPERTY_ATTRIBUTE_ENUM,
  PROPERTY_ATTRIBUTE_MEDIA,
  PROPERTY_ATTRIBUTE_RELATION,
  PROPERTY_ATTRIBUTE_RICHTEXT,
  PROPERTY_ATTRIBUTE_STRING,
  PROPERTY_ATTRIBUTE_TIME,
} from "@/utils/constants";
import { isLocaleDefaultLocale } from "@/utils/localizationUtil";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useRouter } from "next/router";
import { useState } from "react";
import CmsGeneralConfigFrame from "../cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import CmsPropertyDataProvider from "./cmsPropertyDataProvider";
import CmsPropertyAddButton from "./cmsPropertyEditor/cmsPropertyAddButton";
import CmsPropertyAttributeAddModal from "./cmsPropertyEditor/modal/cmsPropertyAttributeAddModal";
import CmsPropertyAttributeEditModal from "./cmsPropertyEditor/modal/cmsPropertyAttributeEditModal";
import CmsPropertyAttributeGroupEditModal from "./cmsPropertyEditor/modal/cmsPropertyAttributeGroupEditModal";
import CmsPropertyMedia from "./cmsPropertyMedia";
import CmsPropertyRelation from "./cmsPropertyRelation";

export interface CmsPropertiesProps {
  propertyAttributeGroups: Array<CmsPropertyAttributeGroup>;
  propertyValues: CmsPropertyValues;
  onChange: (
    attributeGroupName: string,
    attributeName: string,
    value: any
  ) => void;
  accordionDefaultExpanded?: boolean;
  isPropertyEditorPreview?: boolean;
  editorPropertiesAttributePath?: string;
  onEditorChange?: (structureChange?: boolean) => void;
}

export default function CmsProperties(props: CmsPropertiesProps) {
  const tCms = useCmsTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const { richTextClassName, richtextEditorSettings } = useCesStrRichText();

  const [attributeGroupModalOpen, setAttributeGroupModalOpen] =
    useState<boolean>(false);
  const [editAttributeModalOpen, setEditAttributeModalOpen] =
    useState<boolean>(false);
  const [addAttributeModalOpen, setAddAttributeModalOpen] =
    useState<boolean>(false);
  const [attributeModalOpen, setAttributeModalOpen] = useState<boolean>(false);
  const [editedAttributeGroupIndex, setEditedAttributeGroupIndex] = useState<
    number | null
  >(null);
  const [editedAttributeIndex, setEditedAttributeIndex] = useState<
    number | null
  >(null);
  const { showConfirmModal: showAttributeGroupDeleteModal } = useConfirmModal(
    async () => {
      dispatch(
        removeFromArrayAction({
          attributePath: `${props.editorPropertiesAttributePath}`,
          position: editedAttributeGroupIndex!,
        })
      );
      onEditorChange();
    }
  );

  const { showConfirmModal: showAttributeDeleteModal } = useConfirmModal(
    async () => {
      dispatch(
        removeFromArrayAction({
          attributePath: `${
            props.editorPropertiesAttributePath
          }[${editedAttributeGroupIndex!}].attributes`,
          position: editedAttributeIndex!,
        })
      );
      onEditorChange();
    }
  );

  /**
   * callback for parent component that something was edited
   */
  const onEditorChange = (structureChange?: boolean) => {
    if (props.onEditorChange) {
      props.onEditorChange(structureChange);
    }
  };

  const renderGenericPropertyInputField = (
    attributeGroup: CmsPropertyAttributeGroup,
    attribute: CmsPropertyAttribute
  ) => {
    let currentValue =
      props.propertyValues?.[attributeGroup.name]?.[attribute.name];

    if (typeof currentValue === "undefined") {
      return null;
    }

    const isNonLocalizedDisabled =
      editView &&
      attribute.nonLocalized &&
      !isLocaleDefaultLocale(router.locale!);
    switch (attribute.type) {
      case PROPERTY_ATTRIBUTE_STRING:
        return (
          <PbInput
            withCmsStyle
            type="text"
            defaultValue={currentValue}
            fullWidth
            disabled={isNonLocalizedDisabled}
            onChange={(e) => {
              props.onChange(
                attributeGroup.name,
                attribute.name,
                e.target.value
              );
            }}
          />
        );
      case PROPERTY_ATTRIBUTE_RICHTEXT:
        return (
          <>
            {isNonLocalizedDisabled ? (
              <PbRichTextView className="" htmlString={currentValue} />
            ) : (
              <PbRichTextEditor
                className={richTextClassName}
                settings={richtextEditorSettings}
                onChange={(value: string) =>
                  props.onChange(attributeGroup.name, attribute.name, value)
                }
                content={currentValue}
              />
            )}
          </>
        );
      case PROPERTY_ATTRIBUTE_BOOLEAN:
        return (
          <PbCheckBox
            withoutColon
            value={currentValue}
            isSlider={true}
            isDisabled={isNonLocalizedDisabled}
            onChange={() => {
              props.onChange(
                attributeGroup.name,
                attribute.name,
                !currentValue
              );
            }}
            fullHeight
          />
        );
      case PROPERTY_ATTRIBUTE_DATE:
        return (
          <PbDateInput
            fullWidth
            withCmsStyle
            value={currentValue}
            disabled={isNonLocalizedDisabled}
            onChange={(value: any) =>
              props.onChange(attributeGroup.name, attribute.name, value)
            }
          />
        );
      case PROPERTY_ATTRIBUTE_TIME:
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {attribute.optional && (
              <PbCheckBox
                withoutColon
                value={!!currentValue}
                isSlider={true}
                isDisabled={isNonLocalizedDisabled}
                onChange={() => {
                  if (currentValue) {
                    props.onChange(attributeGroup.name, attribute.name, null);
                  } else {
                    props.onChange(
                      attributeGroup.name,
                      attribute.name,
                      "00:00"
                    );
                  }
                }}
                fullHeight
              />
            )}
            <PbTimeInput
              fullWidth
              withCmsStyle
              disabled={
                (attribute.optional && !currentValue) || isNonLocalizedDisabled
              }
              disabledPlaceholder={
                attribute.optional && currentValue === null
                  ? tCms("noTime")
                  : undefined
              }
              value={currentValue ?? "00:00"}
              onChange={(value: string) => {
                props.onChange(attributeGroup.name, attribute.name, value);
              }}
            />
          </div>
        );
      case PROPERTY_ATTRIBUTE_ENUM:
        return (
          <PbDropdown
            withCmsStyle
            disabled={isNonLocalizedDisabled}
            dropdownList={attribute.options!}
            onChange={(event) => {
              props.onChange(
                attributeGroup.name,
                attribute.name,
                event.target.value
              );
            }}
            defaultValue={currentValue}
            itemNameKey={"name"}
            itemValueKey={"value"}
          />
        );
      case PROPERTY_ATTRIBUTE_MEDIA:
        return (
          <CmsPropertyMedia
            attributeGroup={attributeGroup}
            attribute={attribute}
            currentValue={currentValue as CmsPropertyRelationValue}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
      case PROPERTY_ATTRIBUTE_DATAPROVIDER:
        return (
          <CmsPropertyDataProvider
            attributeGroup={attributeGroup}
            attribute={attribute}
            currentValue={currentValue}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
      case PROPERTY_ATTRIBUTE_RELATION:
        return (
          <CmsPropertyRelation
            attributeGroup={attributeGroup}
            attribute={attribute}
            currentValue={currentValue as CmsPropertyRelationValue}
            isNonLocalizedDisabled={isNonLocalizedDisabled}
            onChange={props.onChange}
          />
        );
    }
  };

  if (
    !props.propertyAttributeGroups ||
    !Array.isArray(props.propertyAttributeGroups)
  ) {
    return null;
  }

  return (
    <>
      {Array.isArray(props.propertyAttributeGroups) &&
        props.propertyAttributeGroups?.map((attributeGroup, index) => {
          switch (attributeGroup.name) {
            // add special components for specific groups here if you want to
            default:
              return (
                <CmsGeneralConfigFrame
                  key={index}
                  position={index}
                  hideFrame={!props.isPropertyEditorPreview}
                  frameOnHover
                  arrayLength={props.propertyAttributeGroups.length}
                  title={tCms("group") + ": " + attributeGroup.label}
                  buttonsRight
                  onAddBtnClick={() => {
                    dispatch(
                      addToArrayAction({
                        attributePath: `${props.editorPropertiesAttributePath}`,
                        pos: index + 1,
                        value: {
                          name: props.propertyAttributeGroups?.length
                            ? `group${
                                props.propertyAttributeGroups?.length + 1
                              }`
                            : "group1",
                          label: props.propertyAttributeGroups?.length
                            ? `group-${
                                props.propertyAttributeGroups?.length + 1
                              }`
                            : "group-1",
                          attributes: [],
                        },
                      })
                    );
                    onEditorChange();
                  }}
                  onDelete={() => {
                    setEditedAttributeGroupIndex(index);
                    showAttributeGroupDeleteModal({
                      title: tCms("deleteItemConfirmation"),
                      content: tCms("deleteItemConfirmationLong"),
                      acceptBtnText: tCms("yes"),
                      denyBtnText: tCms("no"),
                      icon: "triangle-exclamation-light",
                    });
                  }}
                  moveUp={() => {
                    dispatch(
                      moveInArrayAction({
                        attributePath: `${props.editorPropertiesAttributePath}[${index}]`,
                        direction: "up",
                      })
                    );
                    onEditorChange();
                  }}
                  moveDown={() => {
                    dispatch(
                      moveInArrayAction({
                        attributePath: `${props.editorPropertiesAttributePath}[${index}]`,
                        direction: "down",
                      })
                    );
                    onEditorChange();
                  }}
                  openContentEditModal={() => {
                    setEditedAttributeGroupIndex(index);
                    setAttributeGroupModalOpen(true);
                  }}
                >
                  <Accordion defaultExpanded={props.accordionDefaultExpanded}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel-${index}-content`}
                      id={`panel-${index}-header`}
                      sx={{
                        fontSize: 18,
                        fontWeight: "600",
                        borderBottom: "1px solid #c2c2c2",
                        borderTop: "1px solid #f2f2f2",
                      }}
                    >
                      {attributeGroup.label}
                      <code
                        className="mx-2"
                        style={{ fontSize: "18px", marginTop: "-2px" }}
                      >{`properties.${attributeGroup.name}`}</code>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="cms-modal-input-wrapper-container">
                        {attributeGroup.attributes?.map(
                          (attribute, attributeIndex) => {
                            return (
                              <CmsGeneralConfigFrame
                                key={attributeIndex}
                                position={attributeIndex}
                                hideFrame={!props.isPropertyEditorPreview}
                                frameOnHover
                                smallTitle
                                buttonsRight
                                arrayLength={attributeGroup.attributes.length}
                                title={
                                  tCms("attribute") +
                                  ": " +
                                  (attribute.label ?? "")
                                }
                                onAddBtnClick={() => {
                                  setEditedAttributeGroupIndex(index);
                                  setEditedAttributeIndex(attributeIndex + 1);
                                  setAddAttributeModalOpen(true);
                                }}
                                onDelete={() => {
                                  setEditedAttributeGroupIndex(index);
                                  setEditedAttributeIndex(attributeIndex);
                                  showAttributeDeleteModal({
                                    title: tCms("deleteItemConfirmation"),
                                    content: tCms("deleteItemConfirmationLong"),
                                    acceptBtnText: tCms("yes"),
                                    denyBtnText: tCms("no"),
                                    icon: "triangle-exclamation-light",
                                  });
                                }}
                                moveUp={() => {
                                  dispatch(
                                    moveInArrayAction({
                                      attributePath: `${props.editorPropertiesAttributePath}[${index}].attributes[${attributeIndex}]`,
                                      direction: "up",
                                    })
                                  );
                                  onEditorChange();
                                }}
                                moveDown={() => {
                                  dispatch(
                                    moveInArrayAction({
                                      attributePath: `${props.editorPropertiesAttributePath}[${index}].attributes[${attributeIndex}]`,
                                      direction: "down",
                                    })
                                  );
                                  onEditorChange();
                                }}
                                openContentEditModal={() => {
                                  setEditedAttributeGroupIndex(index);
                                  setEditedAttributeIndex(attributeIndex);
                                  setEditAttributeModalOpen(true);
                                }}
                              >
                                <div
                                  className={clsx(
                                    props.isPropertyEditorPreview && "pt-5"
                                  )}
                                >
                                  <ModalInputWrapper
                                    fullWidth={attribute.type === "richtext"}
                                    label={attribute.label}
                                    codeDescription={`properties.${attributeGroup.name}.${attribute.name}`}
                                    description={attribute.description}
                                    nonLocalized={attribute.nonLocalized}
                                  >
                                    {renderGenericPropertyInputField(
                                      attributeGroup,
                                      attribute
                                    )}
                                  </ModalInputWrapper>
                                </div>
                              </CmsGeneralConfigFrame>
                            );
                          }
                        )}
                        {props.isPropertyEditorPreview && (
                          <>
                            <CmsPropertyAddButton
                              onClick={() => {
                                setEditedAttributeGroupIndex(index);
                                setEditedAttributeIndex(
                                  attributeGroup.attributes.length + 1
                                );
                                setAddAttributeModalOpen(true);
                              }}
                            >
                              {tCms("add-attribute")}
                            </CmsPropertyAddButton>
                          </>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </CmsGeneralConfigFrame>
              );
          }
        })}
      {props.isPropertyEditorPreview && props.propertyAttributeGroups && (
        <CmsPropertyAddButton
          onClick={() => {
            dispatch(
              addToArrayAction({
                attributePath: `${props.editorPropertiesAttributePath}`,
                value: {
                  name: props.propertyAttributeGroups?.length
                    ? `group${props.propertyAttributeGroups?.length + 1}`
                    : "group1",
                  label: props.propertyAttributeGroups?.length
                    ? `group ${props.propertyAttributeGroups?.length + 1}`
                    : "group 1",
                  attributes: [],
                },
              })
            );
            onEditorChange();
          }}
        >
          {tCms("add-group")}
        </CmsPropertyAddButton>
      )}
      {props.isPropertyEditorPreview && (
        <>
          <CmsPropertyAttributeGroupEditModal
            attributeGroup={
              props.propertyAttributeGroups.find(
                (attributeGroup, index) => index === editedAttributeGroupIndex
              )!
            }
            open={attributeGroupModalOpen}
            setOpen={setAttributeGroupModalOpen}
            onChange={(attributeName: string, value: any) => {
              dispatch(
                updateAttributeAction({
                  attributePath: `${props.editorPropertiesAttributePath}[${editedAttributeGroupIndex}].${attributeName}`,
                  value: value,
                  debounce: DEBOUNCE_DEFAULT_TIME,
                })
              );
            }}
            onClose={() => onEditorChange()}
          />
          <CmsPropertyAttributeEditModal
            attributeGroup={
              props.propertyAttributeGroups.find(
                (attributeGroup, index) => index === editedAttributeGroupIndex
              )!
            }
            attribute={
              props.propertyAttributeGroups
                .find(
                  (attributeGroup, index) => index === editedAttributeGroupIndex
                )
                ?.attributes?.find(
                  (attribute, index) => index === editedAttributeIndex
                )!
            }
            editedAttributeGroupIndex={editedAttributeGroupIndex}
            editedAttributeIndex={editedAttributeIndex}
            editorPropertiesAttributePath={props.editorPropertiesAttributePath}
            open={editAttributeModalOpen}
            setOpen={setEditAttributeModalOpen}
            onChange={(attributeName: string, value: any) => {
              dispatch(
                updateAttributeAction({
                  attributePath:
                    `${props.editorPropertiesAttributePath}[${editedAttributeGroupIndex}]` +
                    `.attributes[${editedAttributeIndex}].${attributeName}`,
                  value: value,
                  debounce: DEBOUNCE_DEFAULT_TIME,
                })
              );
            }}
            onClose={() => onEditorChange()}
          />
          <CmsPropertyAttributeAddModal
            open={addAttributeModalOpen}
            setOpen={setAddAttributeModalOpen}
            attributeGroup={
              props.propertyAttributeGroups.find(
                (attributeGroup, index) => index === editedAttributeGroupIndex
              )!
            }
            newAttributeIndex={editedAttributeIndex!}
            attributesLength={
              props.propertyAttributeGroups.find(
                (attributeGroup, index) => index === editedAttributeGroupIndex
              )?.attributes?.length ?? 0
            }
            onConfirm={async (newAttributeIndex, newAttribute) => {
              dispatch(
                addToArrayAction({
                  attributePath: `${props.editorPropertiesAttributePath}[${editedAttributeGroupIndex}].attributes`,
                  pos: newAttributeIndex,
                  value: newAttribute,
                })
              );
              onEditorChange(true);
            }}
          />
        </>
      )}
    </>
  );
}
