import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttribute } from "@/types/strapi";

export interface CmsPropertyAttributeEditBaseProps {
  attribute: CmsPropertyAttribute | undefined;
  onChange: (attributeName: string, value: any) => void;
}

export default function CmsPropertyAttributeEditBase(
  props: CmsPropertyAttributeEditBaseProps
) {
  const tCms = useCmsTranslation();

  return (
    <>
      <ModalInputWrapper
        label={tCms("label")}
        description={tCms("propertyAttribute-labelDescription")}
      >
        <PbInput
          withCmsStyle
          fullWidth
          placeholder={tCms("label")}
          defaultValue={props.attribute?.label}
          onChange={(e) => {
            props.onChange("label", e.target.value);
          }}
          error={!props.attribute?.label}
          errorText={`${tCms("noInputProvided")} ${tCms(
            "randomValueWillBeUsed"
          )}`}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("name")}
        description={tCms("property-technicalNameDescription")}
      >
        <PbInput
          withCmsStyle
          fullWidth
          placeholder={tCms("name")}
          defaultValue={props.attribute?.name}
          onChange={(e) => {
            props.onChange("name", e.target.value);
          }}
          error={!props.attribute?.name}
          errorText={`${tCms("noInputProvided")} ${tCms(
            "randomValueWillBeUsed"
          )}`}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("description")}
        description={tCms("propertyAttribute-description")}
      >
        <PbInput
          withCmsStyle
          fullWidth
          placeholder={tCms("description")}
          defaultValue={props.attribute?.description}
          onChange={(e) => {
            props.onChange("description", e.target.value);
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("nonLocalized")}
        description={tCms("nonLocalizedDescription")}
      >
        <PbCheckBox
          withoutColon
          value={!!props.attribute?.nonLocalized}
          isSlider={true}
          onChange={() => {
            props.onChange("nonLocalized", !!!props.attribute?.nonLocalized);
          }}
          fullHeight
        />
      </ModalInputWrapper>
    </>
  );
}
