function ToastNotification(props: {
  toastIcon: JSX.Element;
  toastMessage: string;
}) {
  return (
    <div className="row">
      <div className="col-2 col-md-1 my-auto p-0">{props.toastIcon}</div>
      <div className="col-9 col-md-10 offset-1 my-auto">
        <div className="cms-notification-text">{props.toastMessage}</div>
      </div>
    </div>
  );
}

export default ToastNotification;
