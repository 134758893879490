import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttributeBoolean } from "@/types/strapi";

export interface CmsPropertyAttributeEditBooleanProps {
  attribute: CmsPropertyAttributeBoolean | undefined;
  onChange: (attributeName: string, value: any) => void;
}

export default function CmsPropertyAttributeEditBoolean(
  props: CmsPropertyAttributeEditBooleanProps
) {
  const tCms = useCmsTranslation();
  return (
    <ModalInputWrapper
      label={tCms("defaultValue")}
      description={tCms("defaultValueDescription")}
    >
      <PbCheckBox
        withoutColon
        value={!!props.attribute?.default}
        isSlider={true}
        onChange={() => {
          props.onChange("default", !!!props.attribute?.default);
        }}
        fullHeight
      />
    </ModalInputWrapper>
  );
}
