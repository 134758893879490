import useStrColor from "@/hooks/useStrColor";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import SearchIcon from "@mui/icons-material/Search";
const Box = dynamic(() => import("@mui/material/Box"));
const IconButton = dynamic(() => import("@mui/material/IconButton"));
export interface SearchButtonProps {
  hidden: boolean;
  toggleSearchBar: (toggle: boolean) => void;
}

/**
 * SearchButton (in Navigation)
 * @param {*} props
 * @param {*} props.hidden
 * @param {*} props.toggleSearchBar
 */
const SearchButton = (props: SearchButtonProps) => {
  const { t: tPublic } = useTranslation("public");
  const { colorCssVar } = useStrColor(
    globalConfig?.search.navSearchIconColor,
    undefined,
    "var(--pb-black)"
  );
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        visibility: props.hidden ? "hidden" : "visible",
      }}
    >
      <IconButton
        aria-label={tPublic("search")}
        onClick={() => props.toggleSearchBar(true)}
        className="search-button-desktop"
      >
        <SearchIcon
          fontSize="large"
          style={{
            fill: colorCssVar,
          }}
        />
      </IconButton>
    </Box>
  );
};

export default SearchButton;
