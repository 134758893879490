import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useAppSelector } from "@/store/store";
import { MOBILE_SCALING_FACTOR } from "@/utils/constants";
import ViewportPreviewStyles from "../contentelements/wrapper/viewportPreviewWrapper";
import { FooterContentProps } from "./footerContent";

interface FooterContentStyleProps extends FooterContentProps {
  socialMediaList: { title: string; content: Array<any> };
  socialMediaWidth: number;
  footerElementsAlignment: "justify-content-start" | "justify-content-around";
}

export default function FooterContentStyle(props: FooterContentStyleProps) {
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  const repeatableElementWidth =
    globalConfig?.footer?.repeatableElementWidth ?? 290;

  const footerColSpaceXMobile = globalConfig?.footer?.footerCol?.spaceX
    ? globalConfig.footer.footerCol.spaceX * MOBILE_SCALING_FACTOR + "px"
    : "0";
  const footerColSpaceXDesktop = globalConfig?.footer?.footerCol?.spaceX
    ? globalConfig.footer.footerCol?.spaceX + "px"
    : "0";
  const footerColSpaceYMobile = globalConfig?.footer?.footerCol?.spaceY
    ? globalConfig.footer.footerCol?.spaceY * MOBILE_SCALING_FACTOR + "px"
    : "0";
  const footerColSpaceYDesktop = globalConfig?.footer?.footerCol?.spaceY
    ? globalConfig.footer.footerCol?.spaceY + "px"
    : "0";
  const footerMobileBreakPoint =
    globalConfig?.responsive?.breakpoints?.mobile ?? "768";

  const renderFooter =
    (props.footerContent?.content && props.footerContent.content.length > 0) ||
    props.socialMediaList?.title ||
    props.socialMediaList?.content?.length > 0 ||
    editView;

  return (
    <>
      <style jsx global>{`
        :global(.footer-wrapper) {
          :global(.footer),
          :global(.footer .footer-content),
          :global(.social-media-column) {
            ${!renderFooter
              ? "min-height: 0 !important; padding-top: 0 !important; padding-bottom: 0 !important;"
              : ""}
          }
        }

        :global(.footer-col) {
          min-width: ${`min(${repeatableElementWidth}px, 100%)`};
          max-width: ${repeatableElementWidth}px;

          padding-left: ${footerColSpaceXMobile};
          padding-right: ${footerColSpaceXMobile};
          padding-top: ${footerColSpaceYMobile};
          padding-bottom: ${footerColSpaceYMobile};

          @media (min-width: ${footerMobileBreakPoint}px) {
            padding-left: ${footerColSpaceXDesktop};
            padding-right: ${footerColSpaceXDesktop};
            padding-top: ${footerColSpaceYDesktop};
            padding-bottom: ${footerColSpaceYDesktop};
          }
        }

        :global(.social-media-column) {
          min-width: ${`min(${props.socialMediaWidth}px, 100%)`};
          max-width: ${props.socialMediaWidth}px;

          padding-left: ${footerColSpaceXMobile};
          padding-right: ${footerColSpaceXMobile};
          padding-top: ${footerColSpaceYMobile};
          padding-bottom: ${footerColSpaceYMobile};

          @media (min-width: ${footerMobileBreakPoint}px) {
            padding-left: ${footerColSpaceXDesktop};
            padding-right: ${footerColSpaceXDesktop};
            padding-top: ${footerColSpaceYDesktop};
            padding-bottom: ${footerColSpaceYDesktop};
          }

          margin-right: ${props.footerElementsAlignment ===
          "justify-content-start"
            ? "auto"
            : "unset"};
          margin-left: unset;
        }

        .placeholder-footer {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: center;
        }
      `}</style>
      <ViewportPreviewStyles>
        <style>{`
        .social-media-column {
          padding-left: ${footerColSpaceXMobile} !important;
          padding-right: ${footerColSpaceXMobile} !important;
          padding-top: ${footerColSpaceYMobile} !important;
          padding-bottom: ${footerColSpaceYMobile} !important;
        }
        @container (min-width: ${footerMobileBreakPoint}px) {
          .social-media-column {
            padding-left: ${footerColSpaceXDesktop} !important;
            padding-right: ${footerColSpaceXDesktop} !important;
            padding-top: ${footerColSpaceYDesktop} !important;
            padding-bottom: ${footerColSpaceYDesktop} !important;
          }   
        }
        `}</style>
      </ViewportPreviewStyles>
    </>
  );
}
