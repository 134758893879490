interface ContentWrapperStyleProps {
  viewportPreview: string | null;
}

export default function ContentWrapperStyle(props: ContentWrapperStyleProps) {
  return (
    <style jsx global>{`
      .content-wrapper {
        container: ${props.viewportPreview
          ? "content-wrapper / inline-size"
          : "none"};
        max-width: ${props.viewportPreview ? props.viewportPreview : "inherit"};
        margin: 0 auto;
        border: ${!props.viewportPreview ? "0" : "3px solid red"};
      }
    `}</style>
  );
}
