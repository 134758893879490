import { useAppSelector } from "@/store/store";

export interface ViewportPreviewStylesProps {
  children: JSX.Element;
}

export default function ViewportPreviewStyles({
  children,
}: ViewportPreviewStylesProps) {
  const viewportPreview = useAppSelector(
    (state) => state.cmsGeneral.viewportPreview
  );

  if(viewportPreview) {
    return children;
   }
   return null;   
}
