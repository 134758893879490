import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { showLoadingOverlayAction } from "@/store/slices/general/generalSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { useRouter } from "next/router";

const CmsTopBarLeftControlsBackButton = () => {
  const dispatch = useAppDispatch();
  const currentContext = useAppSelector(
    (state) => state.cmsGeneral.currentContext
  );

  const router = useRouter();
  const tCms = useCmsTranslation();

  return (
    <PbButton
      className="cms-top-bar-btn mx-1"
      showOnlyIconBelowWidth={1450}
      color="secondary"
      onClick={async () => {
        dispatch(showLoadingOverlayAction());
        router.push("/");
      }}
      startIcon={
        <PbIcon
          name="angle-left-light"
          width={20}
          height={20}
          className="svg-fill-white"
        />
      }
      disabled={currentContext === null}
    >
      {tCms("backToWYSIWYG")}
    </PbButton>
  );
};

export default CmsTopBarLeftControlsBackButton;
