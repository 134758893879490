import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbTimeInput from "@/components/input/pbTimeInput/pbTimeInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttributeTime } from "@/types/strapi";

export interface CmsPropertyAttributeEditTimeProps {
  attribute: CmsPropertyAttributeTime | undefined;
  onChange: (attributeName: string, value: any) => void;
}

export default function CmsPropertyAttributeEditTime(
  props: CmsPropertyAttributeEditTimeProps
) {
  const tCms = useCmsTranslation();
  return (
    <>
      <ModalInputWrapper
        label={tCms("defaultValue")}
        description={tCms("defaultValueDescription")}
      >
        <PbTimeInput
          fullWidth
          withCmsStyle
          value={props.attribute?.default ?? ""}
          onChange={(value: string) => {
            props.onChange("default", value);
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("isOptional")}
        description={tCms("optionalDescription")}
      >
        <PbCheckBox
          withoutColon
          value={!!props.attribute?.optional}
          isSlider={true}
          onChange={() => {
            props.onChange("optional", !!!props.attribute?.optional);
          }}
          fullHeight
        />
      </ModalInputWrapper>
    </>
  );
}
