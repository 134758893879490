import { useAppSelector } from "@/store/store";
import "react-resizable/css/styles.css";
import ContentWrapperStyle from "./contentWrapperStyle";

interface ContentWrapperProps {
  children: JSX.Element;
}

export default function ContentWrapper(props: ContentWrapperProps) {
  const viewportPreview = useAppSelector(
    (state) => state.cmsGeneral.viewportPreview
  );

  // const [sidebarWidth, setSidebarWidth] = useState(250);

  // const handleSidebarResize = (width: number) => {
  //   setSidebarWidth(width);
  // };

  return (
    <>
      <div style={{ position: "relative" }}>
        {/* <Sidebar onResize={handleSidebarResize} /> */}
        <div
          className={`content-wrapper ${viewportPreview && "viewport-preview"}`}
          style={
            {
              // marginLeft: `${sidebarWidth}px`,
            }
          }
        >
          {props.children}
        </div>
      </div>
      <ContentWrapperStyle viewportPreview={viewportPreview} />
    </>
  );
}
