import { ThemeProvider, createTheme } from "@mui/material";
import { ReactNode } from "react";

interface PublicThemeProviderProps {
  children: ReactNode;
}

/**
 * Contains all MUI styling outside of CMS
 */
export default function PublicThemeProvider(props: PublicThemeProviderProps) {
  const cmsGrey = "#d2d4da";
  const cmsInputTheme = createTheme({
    // set the base font size to 10 because of html font-size 62.5%
    typography: {
      // Tell Material-UI what's the font-size on the html element is.
      htmlFontSize: 10,
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            ".MuiPaper-root": {
              border: "1px solid black",
              borderRadius: "var(--pb-rounded-input-border-radius)",
            },
            ".MuiList-root": {
              padding: 6,
            },
            ".MuiMenuItem-root": {
              fontSize: 14,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: 38,
            fontSize: 16,
            borderRadius: "var(--pb-rounded-input-border-radius)",
            borderColor: cmsGrey,
            "&.Mui-focused": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#198fd9",
              },
            },
            ".MuiSvgIcon-root": {
              fontSize: 24,
            },
            ".Mui-disabled, &.Mui-disabled": {
              cursor: "not-allowed",
              svg: {
                fill: cmsGrey,
              },
            },
          },
        },
      },
    },
  });

  const combinedThemes = { ...cmsInputTheme };

  return <ThemeProvider theme={combinedThemes}>{props.children}</ThemeProvider>;
}
