import PbButton from "@/components/input/pbButton/pbButton";
import LoadingText from "@/components/loadingText/loadingText";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import FilePlaceholderIcon from "@/components/util/filePlaceholderIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { getFileById } from "@/services/cmsMediaManager/cmsMediaManagerService";
import {
  CmsPropertyAttributeGroup,
  CmsPropertyAttributeMedia,
  CmsPropertyRelationValue,
  StrapiUploadFile,
} from "@/types/strapi";
import React, { useEffect, useState } from "react";
import CmsImage from "../cmsImage/cmsImage";
import CmsMediaManagerSelector from "../cmsMediaManager/cmsMediaManagerSelector";
import CmsTooltip from "../cmsTooltip/cmsTooltip";

export interface CmsPropertyMediaProps {
  attributeGroup: CmsPropertyAttributeGroup;
  attribute: CmsPropertyAttributeMedia;
  currentValue: CmsPropertyRelationValue;
  isNonLocalizedDisabled?: boolean;
  onChange: (
    attributeGroupName: string,
    attributeName: string,
    value: any
  ) => void;
}

export default React.memo(function CmsPropertyMedia(
  props: CmsPropertyMediaProps
) {
  const tCms = useCmsTranslation();
  const [mediaFile, setMediaFile] = useState<StrapiUploadFile | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [relationNotFound, setRelationNotFound] = useState<boolean>(false);

  const convertRelationValueToMediaValue = (
    value: CmsPropertyRelationValue | null
  ): number | null => {
    if (!value) {
      return null;
    }
    return value.relationValue ?? null;
  };

  const currentValue = convertRelationValueToMediaValue(props.currentValue);

  useEffect(() => {
    if (currentValue === null) {
      setMediaFile(null);
    } else {
      const getMediaFile = async () => {
        try {
          const selectedMediaFile = await getFileById(currentValue);
          setMediaFile(selectedMediaFile);
          setIsLoading(false);
          setRelationNotFound(false);
        } catch (error) {
          setMediaFile(null);
          setIsLoading(false);
          setRelationNotFound(true);
        }
      };
      setIsLoading(true);
      getMediaFile();
    }
  }, [currentValue]);

  return (
    <div>
      <div>
        <CmsMediaManagerSelector
          disabled={props.isNonLocalizedDisabled}
          onConfirm={(file: StrapiUploadFile) => {
            if (file) {
              if (currentValue === null || file.id !== currentValue) {
                props.onChange(
                  props.attributeGroup.name,
                  props.attribute.name,
                  {
                    model: "plugin::upload.file",
                    relationKey: "id",
                    relationValue: file.id,
                    relation: null,
                    populated: props.attribute.populated ?? true,
                  } as CmsPropertyRelationValue
                );
                setRelationNotFound(false);
              }
            } else {
              if (currentValue !== null) {
                props.onChange(
                  props.attributeGroup.name,
                  props.attribute.name,
                  {
                    model: "plugin::upload.file",
                    relationKey: "id",
                    relationValue: null,
                    relation: null,
                    populated: props.attribute.populated ?? true,
                  } as CmsPropertyRelationValue
                );
                setRelationNotFound(false);
              }
            }
          }}
          mimeTypes={props.attribute.allowedTypes ?? undefined}
        />
      </div>
      {mediaFile ? (
        <>
          <div className="mt-3 d-flex">
            <div>
              {mediaFile?.mime?.includes("image") ? (
                <CmsImage
                  image={{
                    ...mediaFile,
                    formats: undefined,
                    url: mediaFile.formats?.thumbnail
                      ? mediaFile.formats.thumbnail.url
                      : mediaFile.url,
                  }}
                  format="thumbnail"
                  objectFit="cover"
                  height={80}
                  width={80}
                />
              ) : (
                <FilePlaceholderIcon
                  mimeType={mediaFile.mime}
                  height={80}
                  width={80}
                />
              )}
            </div>
            <div className="m-auto">
              <PbButton
                disabled={props.isNonLocalizedDisabled}
                title={tCms("remove")}
                onClick={() => {
                  props.onChange(
                    props.attributeGroup.name,
                    props.attribute.name,
                    {
                      model: "plugin::upload.file",
                      relationKey: "id",
                      relationValue: null,
                      relation: null,
                      populated: props.attribute.populated ?? true,
                    } as CmsPropertyRelationValue
                  );
                  setRelationNotFound(false);
                }}
                startIcon={
                  <PbIcon
                    name="trash-light"
                    width={20}
                    height={20}
                    className="svg-fill-white"
                  />
                }
              ></PbButton>
            </div>
          </div>
          <div className="mt-3 d-flex">
            <p className="text-truncate cms-modal-text" title={mediaFile.name}>
              {mediaFile.name}
            </p>
          </div>
        </>
      ) : (
        <>{!mediaFile && isLoading && currentValue && <LoadingText />}</>
      )}
      {relationNotFound && (
        <>
          <div className="mt-2">
            <PbButton
              disabled={props.isNonLocalizedDisabled}
              title={tCms("remove")}
              onClick={() => {
                props.onChange(
                  props.attributeGroup.name,
                  props.attribute.name,
                  {
                    model: "plugin::upload.file",
                    relationKey: "id",
                    relationValue: null,
                    relation: null,
                    populated: props.attribute.populated ?? true,
                  } as CmsPropertyRelationValue
                );
                setRelationNotFound(false);
              }}
              startIcon={
                <PbIcon
                  name="trash-light"
                  width={20}
                  height={20}
                  className="svg-fill-white"
                />
              }
            ></PbButton>
          </div>
          <div className="d-flex">
            <div>
              <CmsTooltip
                iconName="circle-exclamation-light"
                iconWidth={20}
                iconHeight={20}
                title={tCms("property-relationNotFoundDescription")}
                iconClassName="svg-fill-red"
              />
            </div>
            <div className="my-auto">
              <p className="cms-modal-text">
                {tCms("property-relationNotFound")}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
});
