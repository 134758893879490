import useCmsTranslation from "@/hooks/useCmsTranslation";
import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";
import CmsTooltip from "../cms/cmsTooltip/cmsTooltip";

interface ModalInputWrapperProps {
  label: string;
  description: string;
  /**
   * if true texts and input take both full width, so that the input is below the texts
   */
  fullWidth?: boolean;
  children: React.ReactNode;
  hiddenDescription?: boolean;
  /**
   * if true an icon with the description on hover is shown at the end of the label
   */
  showTooltip?: boolean;
  nonLocalized?: boolean;
  style?: React.CSSProperties;
  className?: string;
  warningText?: string;
  codeDescription?: string;
}
export default function ModalInputWrapper(props: ModalInputWrapperProps) {
  const tCms = useCmsTranslation();
  return (
    <Row
      className={clsx("gx-0", "cms-modal-input-wrapper", props.className)}
      style={props.style}
    >
      <Col xs={props.fullWidth ? 12 : 7}>
        <Row>
          <Col>
            <div className="d-flex">
              <label className="cms-modal-label">
                {props.label}
                {props.showTooltip && (
                  <CmsTooltip
                    iconName="circle-question-light"
                    iconWidth={20}
                    iconHeight={20}
                    title={props.description}
                  />
                )}
                {props.nonLocalized && (
                  <CmsTooltip
                    iconName="globe-light"
                    iconWidth={20}
                    iconHeight={20}
                    title={tCms("onlyChangableInDefaultLanguage")}
                  />
                )}
              </label>
            </div>
          </Col>
        </Row>
        {props.codeDescription && (
          <Row>
            <Col className="my-3">
              <code>{props.codeDescription}</code>
            </Col>
          </Row>
        )}
        {props.hiddenDescription ? null : (
          <Row>
            <Col>
              <div className="cms-modal-text">{props.description}</div>
            </Col>
          </Row>
        )}
      </Col>
      <Col xs={props.fullWidth ? 12 : 5}>
        <div className={clsx("cms-modal-input", !props.warningText && "h-100")}>
          {props.children}
        </div>
        {props.warningText && (
          <div className="warning" style={{ color: "red" }}>
            {props.warningText}
          </div>
        )}
      </Col>
    </Row>
  );
}
