import NavigationDesktop from "@/components/navigation/navigationDesktop/navigationDesktop";
import NavigationMobile from "@/components/navigation/navigationMobile/navigationMobile";
import NavigationStyle from "@/components/navigation/navigationStyle";
import { storeColorCss } from "@/services/ceSettings/ceSettingsService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { NavigationElement } from "@/types/navigation";
import { AppBar, Container, Toolbar } from "@mui/material";
import ViewportPreviewStyles from "../contentelements/wrapper/viewportPreviewWrapper";
import NavigationViewportStyle from "./navigationViewportStyle";

interface NavigationProps {
  navigation: NavigationElement | null;
  hide?: boolean;
  isCmsUserAuthenticated?: boolean;
}

export default function Navigation(props: NavigationProps) {
  if (props.hide || !props.navigation) {
    return null;
  }

  return (
    <div
      className="navigation-container"
      style={{
        position: "sticky",
        top: props.isCmsUserAuthenticated ? "55px" : "0",
        zIndex: 1020,
      }}
    >
      <AppBar
        color="inherit"
        position="static"
        className="navigation"
        id="navbar"
        sx={{
          backgroundColor: storeColorCss(
            globalConfig?.navigation.bgColor,
            "var(--pb-white)"
          ),
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ position: "unset", maxWidth: "100%" }} disableGutters>
            <NavigationMobile navigation={props.navigation} />
            <NavigationDesktop navigation={props.navigation} />
          </Toolbar>
        </Container>
        <NavigationStyle />
      </AppBar>
      <style
        dangerouslySetInnerHTML={{
          __html: globalConfig?.navigation?.additionalCss ?? "",
        }}
      />
      <ViewportPreviewStyles>
        <NavigationViewportStyle />
      </ViewportPreviewStyles>
    </div>
  );
}
