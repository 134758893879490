import PbInput from "@/components/input/pbInput/pbInput";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { Col, Row } from "react-bootstrap";

interface CmsFormFieldOptionInputProps {
  name: string;
  value: any,
  onEditFormFieldOption: any,
  showValueField: any,
  index: number,
}

const CmsFormFieldOptionInput = (props: CmsFormFieldOptionInputProps) => {
  const tCms = useCmsTranslation();
  return (
    <Row className="py-3">
      <Col xs={12}>
        <PbInput
          withCmsStyle
          fullWidth
          label={
            props.showValueField
              ? tCms("form-optionsLabel")
              : tCms("form-optionsLabelAndValue")
          }
          defaultValue={props.name}
          onChange={(e) =>
            props.onEditFormFieldOption(
              "name",
              e.target.value,
              props.index,
              !props.showValueField
            )
          }
        />
        {props.showValueField && (
          <div style={{ paddingTop: "10px" }}>
            <PbInput
              withCmsStyle
              fullWidth
              label={tCms("form-optionsValue")}
              defaultValue={props.value}
              onChange={(e) =>
                props.onEditFormFieldOption(
                  "value",
                  e.target.value,
                  props.index
                )
              }
            />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default CmsFormFieldOptionInput;
