import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";

export interface CmsPropertyAddButtonProps {
  children: any;
  onClick: () => void;
}

export default function CmsPropertyAddButton(props: CmsPropertyAddButtonProps) {
  return (
    <div className="d-flex p-2">
      <PbButton
        className="m-auto"
        onClick={props.onClick}
        startIcon={
          <PbIcon
            width={18}
            height={18}
            name="plus-light"
            className="svg-fill-white"
          />
        }
      >
        {props.children}
      </PbButton>
    </div>
  );
}
