import Navigation from "@/components/navigation/navigation";
import useBeforeunload from "@/hooks/useBeforeUnload";
import useLanguageCookie from "@/hooks/useLanguageCookie";
import useNavigateBackCallback from "@/hooks/useNavigateBackCallback";
import usePageClassNames from "@/hooks/usePageClassNames";
import { usePageVisibility } from "@/hooks/usePageVisibility";
import useScrollState from "@/hooks/useScrollState";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useAppSelector } from "@/store/store";
import { NavigationElement } from "@/types/navigation";
import { getFavIcons } from "@/utils/imageUtil";
import { buildTags } from "@/utils/util";
import clsx from "clsx";
import dynamic from "next/dynamic";
import Head from "next/head";
import { ReactElement } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CmsTopBar from "../cms/cmsTopBar/cmsTopBar";
import Footer from "../footer/footer";
import LoadingSpinnerOverlay from "../loadingSpinnerOverlay/loadingSpinnerOverlay";
import ConfirmModal from "../modal/confirmModal";
import ContentWrapper from "./contentWrapper/contentWrapper";

const AuthenticatedCMSUserComponent = dynamic(
  () => import("./authenticatedUserComponent")
);

const ScrollToTop = dynamic(() => import("../scrollTotop/scrollToTop"));

/**
 * Layout component that wraps around the main content of each page.
 *
 * The Layout component ensures that each page has the following structure:
 * - Navigation Bar: Included at the top of every page that has the
 *    navigation enabled.
 * - Footer: Included at the bottom of every page.
 * - CmsTopBar: Displayed at the top of the page if the content manager
 *    is authenticated.
 * - ToastContainer: Included to display toast notifications.
 *
 * @param {Object} props - The properties passed to the Layout component.
 * @param {NavigationElement[]} props.navigations - Array of navigations
 *    (currently there can be only one navigation (main nav))
 * @param {React.ReactElement} props.children - The main content to be
 *    wrapped by the Layout.
 * @returns {JSX.Element} The Layout component with consistent page structure.
 */
export default function Layout(props: {
  navigations: NavigationElement[];
  children: ReactElement;
  headScripts: string;
  bodyScripts: string;
  cachedAt: string;
}) {
  const isCmsUserAuthenticated = useAppSelector(
    (state) => state.cmsUser.isCmsUserAuthenticated
  );
  useBeforeunload();
  useNavigateBackCallback();
  usePageVisibility();
  useLanguageCookie();
  const scrollState = useScrollState();

  const pageClassNames = usePageClassNames(props.children.props.page);

  const {
    favicon32,
    favicon64,
    favicon128,
    favicon192,
    faviconAndroid,
    faviconApple120,
    faviconApple152,
    faviconApple167,
    faviconApple180,
  } = getFavIcons();

  const showNavigation =
    !props.children.props.page || props.children.props.page.hasNavigation;

  const mainNavigation = props.navigations?.[0] ?? null;

  return (
    <>
      <div
        id="layout"
        className={clsx(
          "layout",
          `scroll-${scrollState}`,
          pageClassNames,
          `c-${props.cachedAt}`
        )}
        data-tempdisableanimations="false"
      >
        <Head>
          {favicon32 && <link rel="icon" href={favicon32} sizes="32x32" />}
          {favicon64 && <link rel="icon" href={favicon64} sizes="64x64" />}
          {favicon128 && <link rel="icon" href={favicon128} sizes="128x128" />}
          {favicon192 && <link rel="icon" href={favicon192} sizes="192x192" />}

          {faviconAndroid && (
            <link rel="shortcut icon" href={faviconAndroid} sizes="196x196" />
          )}

          {faviconApple120 && (
            <link
              rel="apple-touch-icon"
              href={faviconApple120}
              sizes="120x120"
            />
          )}
          {faviconApple152 && (
            <link
              rel="apple-touch-icon"
              href={faviconApple152}
              sizes="152x152"
            />
          )}
          {faviconApple167 && (
            <link
              rel="apple-touch-icon"
              href={faviconApple167}
              sizes="167x167"
            />
          )}
          {faviconApple180 && (
            <link
              rel="apple-touch-icon"
              href={faviconApple180}
              sizes="180x180"
            />
          )}
          {buildTags(props.headScripts)}
        </Head>

        <div className="combined-sticky-elements">
          {isCmsUserAuthenticated && (
            <>
              <CmsTopBar page={props.children.props.page} />
              <AuthenticatedCMSUserComponent />
            </>
          )}
        </div>
        <ContentWrapper>
          <>
            <Navigation
              navigation={mainNavigation}
              hide={!showNavigation}
              isCmsUserAuthenticated={isCmsUserAuthenticated}
            />
            {/* Used by scrollToTop-Hook. */}
            <div id="scroll-to-top-anchor"></div>
            {/* We might want to add some device-identifier (with useWindowDimensionHook) classNames here.
            Like: mobile/tablet/desktop to improve customCSS-Queries & reduce useWindowDimensionHook-Usage. */}
            <main className="main">{props.children}</main>
            {globalConfig?.scrolltotop?.enabled && <ScrollToTop />}
            <LoadingSpinnerOverlay />
            {props.children.props.page ? (
              props.children.props.page.hasFooter ? (
                <Footer />
              ) : null
            ) : (
              <Footer />
            )}
            <ToastContainer />
            <ConfirmModal />
          </>
        </ContentWrapper>
      </div>
      <div dangerouslySetInnerHTML={{ __html: props.bodyScripts }}></div>
    </>
  );
}
