import { updatePageTypeRequest } from "@/services/cmsPageTypeService/cmsPageTypeService";
import { cmsTranslate } from "@/services/cmsTranslation/cmsTranslationService";
import { ReduxThunkAction } from "@/store/store";
import {
  hideLoadingOverlayAction,
  showLoadingOverlayAction,
} from "../general/generalSlice";

export const cmsUpdatePageTypeThunk =
  (): ReduxThunkAction => async (dispatch, getState) => {
    dispatch(
      showLoadingOverlayAction(cmsTranslate("pageType-updatingPagePageType"))
    );
    const cmsSavePageTypeResult = await updatePageTypeRequest(
      getState().cmsEdit.data?.editPageType?.id,
      getState().cmsEdit.data?.editPageType
    );
    dispatch(hideLoadingOverlayAction());
    if (!cmsSavePageTypeResult.success) {
      return Promise.reject();
    }

    return Promise.resolve();
  };
