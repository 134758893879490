import PbGenericModal from "@/components/genericModal/pbGenericModal";
import PbGenericModalStyle from "@/components/genericModal/pbGenericModalStyle";
import LocalizedJsonField from "@/components/localizedJsonField/localizedJsonField";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { PbCategory } from "@/types/strapi";

interface CmsEditCategoryModalProps {
  category: PbCategory;
  onCategoryChange: (locale: string, value: string) => void;
  show: boolean;
  onConfirm: () => void;
  onHide: () => void;
}

/**
 * Modal to edit a category
 */
export default function CmsEditCategoryModal(props: CmsEditCategoryModalProps) {
  const tCms = useCmsTranslation();
  const changeCategory = (locale: string, value: string) => {
    props.onCategoryChange(locale, value);
  };

  return (
    <>
      <PbGenericModal
        open={props.show}
        title={tCms("category-edit")}
        buttonConfirmLabel={tCms("accept")}
        buttonCancelLabel={tCms("cancel")}
        onConfirmClick={() => props.onConfirm()}
        onCancelClick={props.onHide}
        muiDialogProps={{ maxWidth: "sm", fullWidth: true }}
        hideCloseIcon={false}
        onCloseIconClick={props.onHide}
        className="cms-category-modal cms-modal"
      >
        <LocalizedJsonField
          jsonField={props.category.localizedName}
          onChange={changeCategory}
          defaultDescription={tCms("category-nameDescriptionDefaultLanguage")}
          localesDescription={tCms("category-nameDescription")}
        />
      </PbGenericModal>
      <PbGenericModalStyle />
    </>
  );
}
