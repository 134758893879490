import CmsTabs from "@/components/cms/cmsTabs/cmsTabs";
import PbGenericModal from "@/components/genericModal/pbGenericModal";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import {
  CmsPropertyAttribute,
  CmsPropertyAttributeBoolean,
  CmsPropertyAttributeDataProvider,
  CmsPropertyAttributeDate,
  CmsPropertyAttributeEnum,
  CmsPropertyAttributeGroup,
  CmsPropertyAttributeMedia,
  CmsPropertyAttributeRelation,
  CmsPropertyAttributeRichtext,
  CmsPropertyAttributeString,
  CmsPropertyAttributeTime,
} from "@/types/strapi";
import {
  PROPERTY_ATTRIBUTE_BOOLEAN,
  PROPERTY_ATTRIBUTE_DATAPROVIDER,
  PROPERTY_ATTRIBUTE_DATE,
  PROPERTY_ATTRIBUTE_ENUM,
  PROPERTY_ATTRIBUTE_MEDIA,
  PROPERTY_ATTRIBUTE_RELATION,
  PROPERTY_ATTRIBUTE_RICHTEXT,
  PROPERTY_ATTRIBUTE_STRING,
  PROPERTY_ATTRIBUTE_TIME,
} from "@/utils/constants";
import { getRandomSlug } from "@/utils/util";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import clsx from "clsx";
import { useState } from "react";
import CmsPropertyAttributeEditBase from "../cmsPropertyAttributeEditBase";
import CmsPropertyAttributeEditBoolean from "../cmsPropertyAttributeEditBoolean";
import CmsPropertyAttributeEditDataProvider from "../cmsPropertyAttributeEditDataProvider";
import CmsPropertyAttributeEditDate from "../cmsPropertyAttributeEditDate";
import CmsPropertyAttributeEditEnum from "../cmsPropertyAttributeEditEnum";
import CmsPropertyAttributeEditEnumOptions from "../cmsPropertyAttributeEditEnumOptions";
import CmsPropertyAttributeEditMedia from "../cmsPropertyAttributeEditMedia";
import CmsPropertyAttributeEditRelation from "../cmsPropertyAttributeEditRelation";
import CmsPropertyAttributeEditRichText from "../cmsPropertyAttributeEditRichText";
import CmsPropertyAttributeEditString from "../cmsPropertyAttributeEditString";
import CmsPropertyAttributeEditTime from "../cmsPropertyAttributeEditTime";

export interface CmsPropertyAttributeEditModalProps {
  open: boolean;
  attributeGroup: CmsPropertyAttributeGroup;
  attribute: CmsPropertyAttribute | undefined;
  editedAttributeGroupIndex: number | null;
  editedAttributeIndex: number | null;
  editorPropertiesAttributePath: string | undefined;
  setOpen: (value: boolean) => void;
  onChange: (attributeName: string, value: any) => void;
  onClose: () => void;
}

export default function CmsPropertyAttributeEditModal(
  props: CmsPropertyAttributeEditModalProps
) {
  const tCms = useCmsTranslation();
  const [tabValue, setTabValue] = useState<string>("0");

  const closeModal = () => {
    setTabValue("0");
    if (!props.attribute?.name) {
      props.onChange("name", "property_" + getRandomSlug());
    }
    if (!props.attribute?.label) {
      props.onChange(
        "label",
        "property " + props.attribute?.type + " " + getRandomSlug()
      );
    }
    props.onClose();
    props.setOpen(false);
  };

  return (
    <PbGenericModal
      className="cms-property-editor-modal cms-modal with-tabs"
      open={props.open}
      title={tCms("edit-attribute", {
        type: tCms(`propertyattributeType-${props.attribute?.type}`),
      })}
      muiDialogProps={{
        fullWidth: true,
        maxWidth: "md",
      }}
      onConfirmClick={() => {
        closeModal();
      }}
      onCancelClick={() => {
        closeModal();
      }}
      onCloseIconClick={() => {
        closeModal();
      }}
    >
      <CmsTabs>
        <TabContext value={tabValue}>
          <TabList
            className="tab-list"
            aria-label={tCms("selectTab")}
            onChange={(event: any, newValue: string) => {
              setTabValue(newValue);
            }}
          >
            <Tab label={tCms("settings")} value="0" />
            <Tab
              label={tCms("dropdownValues")}
              value="1"
              className={clsx(
                props.attribute?.type !== PROPERTY_ATTRIBUTE_ENUM && "d-none"
              )}
            />
          </TabList>
          <TabPanel value="0">
            <div className="cms-modal-input-wrapper-container">
              <CmsPropertyAttributeEditBase
                attribute={props.attribute}
                onChange={(attributeName, value) =>
                  props.onChange(attributeName, value)
                }
              />
              {props.attribute?.type === PROPERTY_ATTRIBUTE_STRING && (
                <CmsPropertyAttributeEditString
                  attribute={props.attribute as CmsPropertyAttributeString}
                  onChange={(attributeName, value) =>
                    props.onChange(attributeName, value)
                  }
                />
              )}
              {props.attribute?.type === PROPERTY_ATTRIBUTE_RICHTEXT && (
                <CmsPropertyAttributeEditRichText
                  attribute={props.attribute as CmsPropertyAttributeRichtext}
                  onChange={(attributeName, value) =>
                    props.onChange(attributeName, value)
                  }
                />
              )}
              {props.attribute?.type === PROPERTY_ATTRIBUTE_BOOLEAN && (
                <CmsPropertyAttributeEditBoolean
                  attribute={props.attribute as CmsPropertyAttributeBoolean}
                  onChange={(attributeName, value) =>
                    props.onChange(attributeName, value)
                  }
                />
              )}
              {props.attribute?.type === PROPERTY_ATTRIBUTE_DATE && (
                <CmsPropertyAttributeEditDate
                  attribute={props.attribute as CmsPropertyAttributeDate}
                  onChange={(attributeName, value) =>
                    props.onChange(attributeName, value)
                  }
                />
              )}
              {props.attribute?.type === PROPERTY_ATTRIBUTE_TIME && (
                <CmsPropertyAttributeEditTime
                  attribute={props.attribute as CmsPropertyAttributeTime}
                  onChange={(attributeName, value) =>
                    props.onChange(attributeName, value)
                  }
                />
              )}
              {props.attribute?.type === PROPERTY_ATTRIBUTE_ENUM && (
                <CmsPropertyAttributeEditEnum
                  attribute={props.attribute as CmsPropertyAttributeEnum}
                  onChange={(attributeName, value) =>
                    props.onChange(attributeName, value)
                  }
                />
              )}
              {props.attribute?.type === PROPERTY_ATTRIBUTE_MEDIA && (
                <CmsPropertyAttributeEditMedia
                  attribute={props.attribute as CmsPropertyAttributeMedia}
                  onChange={(attributeName, value) =>
                    props.onChange(attributeName, value)
                  }
                />
              )}
              {props.attribute?.type === PROPERTY_ATTRIBUTE_DATAPROVIDER && (
                <CmsPropertyAttributeEditDataProvider
                  attribute={
                    props.attribute as CmsPropertyAttributeDataProvider
                  }
                  onChange={(attributeName, value) =>
                    props.onChange(attributeName, value)
                  }
                />
              )}
              {props.attribute?.type === PROPERTY_ATTRIBUTE_RELATION && (
                <CmsPropertyAttributeEditRelation
                  attribute={props.attribute as CmsPropertyAttributeRelation}
                  onChange={(attributeName, value) =>
                    props.onChange(attributeName, value)
                  }
                />
              )}
            </div>
          </TabPanel>
          <TabPanel
            value="1"
            className={clsx(
              props.attribute?.type !== PROPERTY_ATTRIBUTE_ENUM && "d-none"
            )}
          >
            {props.attribute?.type === PROPERTY_ATTRIBUTE_ENUM && (
              <CmsPropertyAttributeEditEnumOptions
                attribute={props.attribute as CmsPropertyAttributeEnum}
                editedAttributeGroupIndex={props.editedAttributeGroupIndex}
                editedAttributeIndex={props.editedAttributeIndex}
                editorPropertiesAttributePath={
                  props.editorPropertiesAttributePath
                }
              />
            )}
          </TabPanel>
        </TabContext>
      </CmsTabs>
    </PbGenericModal>
  );
}
