import { lowerBreakpointDesktopPx } from "@/utils/util";

export default function NavigationViewportStyle() {
  return (
    <>
      <style>
        {`
          .navigation-desktop {
            display: none;
          }
          .navigation-mobile {
            display: flex;
          }
          @container content-wrapper (min-width: ${lowerBreakpointDesktopPx()}) {
            .navigation-desktop {
              display: flex;
            }
            .navigation-mobile {
              display: none;
            }
        }`}
      </style>
    </>
  );
}
