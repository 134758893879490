export const CmsGeneralConfigFrameStyle = () => {
  return (
    <style jsx global>{`
      .general-config-frame.frame {
        border: solid 2px var(--pb-cms-color);
        border-radius: 5px;
      }
      .general-config-frame {
        border: solid 2px transparent;
        border-radius: 5px;
        position: relative;
        width: calc(100% - 4px);
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;

        /* change next 3 lines if less white space is required */
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding: 0;

        .frame-on-hover {
          border: solid 2px transparent;
          border-radius: 0;
        }

        .general-config-frame-bar {
          display: none;

          .left-controls {
            display: flex;
            z-index: 990;
            top: 0;
            left: 0;
            position: absolute;
            transform: translate(10px, -50%);
          }

          .title {
            text-align: center;
            display: flex;
            color: #fff;
            top: 0;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            background-color: var(--pb-cms-color);
            z-index: 990;
            padding: 0.5rem 5rem;
            border-radius: 5px;
            font-size: 16px;

            &.small-title {
              padding: 0.5rem 0.75rem;
              white-space: nowrap;
            }
          }

          .right-controls {
            display: flex;
            z-index: 990;
            top: 0;
            left: 100%;
            position: absolute;
            transform: translate(calc(-100% - 10px), -50%);

            button:hover {
              background-color: var(--pb-cms-color-hover);
            }
          }
        }
      }

      .frame-on-hover:hover > .general-config-frame-bar,
      .frame-on-hover:focus-within > .general-config-frame-bar,
      .frame > .general-config-frame-bar {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: fit-content;
        .cms-config-frame-btn {
          background-color: var(--pb-cms-color);
          pointer-events: all;

          &.no-text {
            min-width: 33px;
            max-height: 37px;
            border-radius: 0px;
          }
          &:first-of-type {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &:last-of-type {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      .frame-on-hover:hover,
      .frame-on-hover:focus-within {
        border: solid 2px var(--pb-cms-color);
        border-radius: 5px;
      }
    `}</style>
  );
};
