import PbGenericModal from "@/components/genericModal/pbGenericModal";
import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttributeGroup } from "@/types/strapi";
import { getRandomSlug } from "@/utils/util";

export interface CmsPropertyAttributeGroupEditModalProps {
  open: boolean;
  attributeGroup: CmsPropertyAttributeGroup;
  setOpen: (value: boolean) => void;
  onChange: (attributeName: string, value: any) => void;
  onClose: () => void;
}

export default function CmsPropertyAttributeGroupEditModal(
  props: CmsPropertyAttributeGroupEditModalProps
) {
  const tCms = useCmsTranslation();

  const closeModal = () => {
    if (!props.attributeGroup?.name) {
      props.onChange("name", "group" + getRandomSlug());
    }
    if (!props.attributeGroup?.label) {
      props.onChange("label", "Group " + getRandomSlug());
    }
    props.onClose();
    props.setOpen(false);
  };

  return (
    <PbGenericModal
      className="cms-modal cms-property-editor-modal"
      open={props.open}
      title={tCms("edit-attribute-group")}
      muiDialogProps={{
        fullWidth: true,
        maxWidth: "md",
      }}
      onConfirmClick={() => {
        closeModal();
      }}
      onCancelClick={() => {
        closeModal();
      }}
      onCloseIconClick={() => {
        closeModal();
      }}
    >
      <div className="cms-modal-input-wrapper-container">
        <ModalInputWrapper
          label={tCms("label")}
          description={tCms("propertyGroup-labelDescription")}
        >
          <PbInput
            withCmsStyle
            fullWidth
            placeholder={tCms("label")}
            defaultValue={props.attributeGroup?.label}
            onChange={(e) => {
              props.onChange("label", e.target.value);
            }}
            error={!props.attributeGroup?.label}
            errorText={`${tCms("noInputProvided")} ${tCms(
              "randomValueWillBeUsed"
            )}`}
          />
        </ModalInputWrapper>
        <ModalInputWrapper
          label={tCms("name")}
          description={tCms("property-technicalNameDescription")}
        >
          <PbInput
            withCmsStyle
            fullWidth
            placeholder={tCms("name")}
            defaultValue={props.attributeGroup?.name}
            onChange={(e) => {
              props.onChange("name", e.target.value);
            }}
            error={!props.attributeGroup?.name}
            errorText={`${tCms("noInputProvided")} ${tCms(
              "randomValueWillBeUsed"
            )}`}
          />
        </ModalInputWrapper>
      </div>
    </PbGenericModal>
  );
}
