import { ThemeProvider, createTheme } from "@mui/material";
import { ReactNode } from "react";

interface CmsThemeProviderProps {
  children: ReactNode;
}

/**
 * Contains all input CMS MUI styling
 */
export default function CmsThemeProvider(props: CmsThemeProviderProps) {
  const cmsGrey = "#d2d4da";
  const cmsInputTheme = createTheme({
    // set the base font size to 10 because of html font-size 62.5%
    typography: {
      // Tell Material-UI what's the font-size on the html element is.
      htmlFontSize: 10,
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          popper: {
            backgroundColor: "transparent",
            borderRadius: "var(--pb-rounded-input-border-radius)",
            ".MuiPaper-root": {
              border: "1px solid white",
              marginTop: 6,
              backgroundColor: "var(--pb-cms-variable-white)",
              ".MuiAutocomplete-noOptions": {
                fontSize: 14,
              },
            },
            ".MuiAutocomplete-listbox": {
              fontSize: 14,
            },
            ".MuiListSubheader-root": {
              fontSize: 14,
            },
            ".default-page-indicator": {
              marginLeft: 6,
            },
            ul: {
              paddingLeft: 0,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            ".MuiPaper-root": {
              border: "1px solid white",
              borderRadius: "var(--pb-rounded-input-border-radius)",
            },
            ".MuiList-root": {
              padding: 6,
            },
            ".MuiMenuItem-root": {
              fontSize: 14,
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            cursor: "default",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: 38,
            fontSize: 16,
            borderRadius: "var(--pb-rounded-input-border-radius)",
            borderColor: cmsGrey,
            ".MuiInputBase-input::-webkit-calendar-picker-indicator": {
              display: "none",
            },
            "&.Mui-focused": {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "var(--pb-cms-color)",
              },
            },
            ".MuiSvgIcon-root": {
              fontSize: 24,
            },
            ".Mui-disabled, &.Mui-disabled": {
              cursor: "not-allowed",
              svg: {
                fill: cmsGrey,
              },
              ".MuiOutlinedInput-input": {
                cursor: "not-allowed",
              },
            },
            ".MuiChip-label": {
              fontSize: 13,
            },
            ".MuiChip-deleteIcon": {
              fontSize: 16,
            },
            ".cms-input-startadornment-chip": {
              marginRight: 5,
              marginLeft: -10,
              padding: "5px 5px 2px 5px",
              border: "1px solid #808080",
              borderRadius: 4,
              backgroundColor: "#808080",
              height: 30,
              display: "flex",
              alignItems: "center",
              ".chip-text": {
                maxWidth: 65,
                fontSize: 14,
                color: "white",
                textOverflow: "ellipsis",
                overflow: "hidden",
                transition: "max-width 0.66s ease-in",
                "&:hover": {
                  cursor: "default",
                  maxWidth: 200,
                  transition: "max-width 0.66s ease-in",
                },
              },
            },
          },
        },
      },
    }
  });

  const combinedThemes = { ...cmsInputTheme };

  return <ThemeProvider theme={combinedThemes}>{props.children}</ThemeProvider>;
}
