import PbDateInput from "@/components/input/pbDateInput/pbDateInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttributeDate } from "@/types/strapi";

export interface CmsPropertyAttributeEditDateProps {
  attribute: CmsPropertyAttributeDate | undefined;
  onChange: (attributeName: string, value: any) => void;
}

export default function CmsPropertyAttributeEditDate(
  props: CmsPropertyAttributeEditDateProps
) {
  const tCms = useCmsTranslation();
  return (
    <ModalInputWrapper
      label={tCms("defaultValue")}
      description={tCms("defaultValueDescription")}
    >
      <PbDateInput
        fullWidth
        withCmsStyle
        value={props.attribute?.default}
        onChange={(value: any) => {
          props.onChange("default", value);
        }}
      />
    </ModalInputWrapper>
  );
}
