import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttributeDataProvider } from "@/types/strapi";

export interface CmsPropertyAttributeEditDataProviderProps {
  attribute: CmsPropertyAttributeDataProvider | undefined;
  onChange: (attributeName: string, value: any) => void;
}

export default function CmsPropertyAttributeEditDataProvider(
  props: CmsPropertyAttributeEditDataProviderProps
) {
  const tCms = useCmsTranslation();
  return (
    <>
      <ModalInputWrapper
        label={tCms("propertyAttribute-url")}
        description={tCms("propertyAttribute-urlDesc")}
      >
        <PbInput
          withCmsStyle
          fullWidth
          placeholder={tCms("propertyAttribute-url")}
          defaultValue={props.attribute?.url}
          onChange={(e) => {
            props.onChange("url", e.target.value);
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("propertyAttribute-labelAttribute")}
        description={tCms("propertyAttribute-labelAttributeDesc")}
      >
        <PbInput
          withCmsStyle
          fullWidth
          placeholder={"name"}
          defaultValue={props.attribute?.labelAttribute}
          onChange={(e) => {
            props.onChange("labelAttribute", e.target.value);
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("propertyAttribute-valueAttribute")}
        description={tCms("propertyAttribute-valueAttributeDesc")}
      >
        <PbInput
          withCmsStyle
          fullWidth
          placeholder={"id"}
          defaultValue={props.attribute?.valueAttribute}
          onChange={(e) => {
            props.onChange("valueAttribute", e.target.value);
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("propertyAttribute-multiple")}
        description={tCms("propertyAttribute-multipleDesc")}
      >
        <PbCheckBox
          withoutColon
          value={!!props.attribute?.multiple}
          isSlider={true}
          onChange={() => {
            props.onChange("multiple", !!!props.attribute?.multiple);
          }}
          fullHeight
        />
      </ModalInputWrapper>
    </>
  );
}
