import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttributeString } from "@/types/strapi";

export interface CmsPropertyAttributeEditStringProps {
  attribute: CmsPropertyAttributeString | undefined;
  onChange: (attributeName: string, value: any) => void;
}

export default function CmsPropertyAttributeEditString(
  props: CmsPropertyAttributeEditStringProps
) {
  const tCms = useCmsTranslation();
  return (
    <ModalInputWrapper
      label={tCms("defaultValue")}
      description={tCms("defaultValueDescription")}
    >
      <PbInput
        withCmsStyle
        fullWidth
        placeholder={tCms("defaultValue")}
        defaultValue={props.attribute?.default}
        onChange={(e) => {
          props.onChange("default", e.target.value);
        }}
      />
    </ModalInputWrapper>
  );
}
