import PbRichTextEditor from "@/components/input/pbRichTextEditor/pbRichTextEditor";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttributeRichtext } from "@/types/strapi";

export interface CmsPropertyAttributeEditRichTextProps {
  attribute: CmsPropertyAttributeRichtext | undefined;
  onChange: (attributeName: string, value: any) => void;
}

export default function CmsPropertyAttributeEditRichText(
  props: CmsPropertyAttributeEditRichTextProps
) {
  const tCms = useCmsTranslation();
  const { richTextClassName, richtextEditorSettings } = useCesStrRichText();
  return (
    <ModalInputWrapper
      label={tCms("defaultValue")}
      description={tCms("defaultValueDescription")}
      fullWidth
    >
      <PbRichTextEditor
        className={richTextClassName}
        settings={richtextEditorSettings}
        onChange={(value: string) => props.onChange("default", value)}
        content={props.attribute?.default ?? ""}
      />
    </ModalInputWrapper>
  );
}
