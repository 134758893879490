export interface FooterViewportStyleProps {
  socialMediaSnapBreakpoint: number;
  footerElementsAlignmentLeft: boolean;
}

export const FooterViewportStyle = (props: FooterViewportStyleProps) => {
  return (
    <style>{`
      @container content-wrapper (min-width: ${props.socialMediaSnapBreakpoint}px) {
        .social-media-column {
            margin-left: ${
              props.footerElementsAlignmentLeft ? "auto" : "unset"
            } !important;
            margin-right: ${
              props.footerElementsAlignmentLeft ? "unset" : "unset"
            } !important;
          }
      }
    `}</style>
  );
};
