import CmsPageCreateModal from "@/components/cms/cmsPage/cmsPageCreateModal";
import CmsPageSettingsModal from "@/components/cms/cmsPage/cmsPageSettingsModal";
import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsMediaManagerSelector from "@/hooks/useCmsMediaManagerSelector";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import { getPageTypeNameLocalized } from "@/services/cmsPageTypeService/cmsPageTypeService";
import { showCreatePageModalAction } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { logoutCmsUserThunk } from "@/store/slices/cmsUser/cmsUserThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { PbPageType } from "@/types/strapi";
import {
  CONTEXT_CATEGORY,
  CONTEXT_FORM,
  CONTEXT_NAVIGATION,
  CONTEXT_PAGE,
  CONTEXT_PAGETYPE,
} from "@/utils/constants";
import { createToastError, createToastSuccess } from "@/utils/utilComponents";
import { Menu, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import React, { useState } from "react";
import CmsAboutModal from "../cmsAbout/cmsAboutModal";
import CmsLanguageSelector from "../cmsLanguageSelector/cmsLanguageSelector";
import CmsLocaleSyncWarning from "../cmsLocaleSyncWarning/cmsLocaleSyncWarning";
import CmsUserSettingsModal from "../cmsUser/cmsUserSettingsModal";

export const menuStyle = [
  { mt: "35px" },
  {
    ".MuiMenu-paper": {
      borderRadius: "0px !important",
      top: "20px !important",
    },
  },
  {
    ".MuiList-root.MuiList-padding.MuiMenu-list": {
      backgroundColor: "#2a3035",
      borderRadius: "0px",
      border: "unset",
    },
  },
];

export const menuItemStyle = {
  "&:hover": { backgroundColor: "#48515b" },
  "&.Mui-selected": {
    backgroundColor: "#48515b",
    "&:hover": { backgroundColor: "#48515b" },
  },
  paddingLeft: "12px",
  paddingRight: "12px",
};

export const typographyStyle = {
  color: "white !important",
  fontSize: "16px !important",
};

/**
 * Represents the props for the CmsTopBarProps component.
 */
export interface CmsTopBarRightControlsProps {}

/**
 * CmsInputLabel component represents a label for an input field.
 * @param props - The component properties.
 * @returns A JSX element representing the CmsInputLabel.
 */
const CmsTopBarRightControls = (
  props: CmsTopBarRightControlsProps
): JSX.Element => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const tCms = useCmsTranslation();
  const { openMediaManagerSelector } = useCmsMediaManagerSelector();
  const cmsUserEmail = useAppSelector((state) => state.cmsUser.user.email);
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const pageTypes = useAppSelector((state) => state.cmsGeneral.pageTypes);
  const localesStatus = useAppSelector(
    (state) => state.cmsGeneral.localesStatus
  );

  const currentContext = useAppSelector(
    (state) => state.cmsGeneral.currentContext
  );

  const [anchorElMenuAdd, setAnchorElMenuAdd] =
    React.useState<null | HTMLElement>(null);
  const [anchorElMenuManage, setAnchorElMenuManage] =
    React.useState<null | HTMLElement>(null);
  const [anchorElMenuUser, setAnchorElMenuUser] =
    React.useState<null | HTMLElement>(null);

  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const [showCmsUserSettingsModal, setShowCmsUserSettingsModal] =
    useState(false);
  const [showCmsAboutModal, setShowCmsAboutModal] = useState(false);

  const handleLogout = async () => {
    try {
      await dispatch(logoutCmsUserThunk());
      createToastSuccess(tCms("logoutSuccess"));
      router.push("/");
    } catch (error) {
      createToastError(tCms("logoutError"));
    }

    setAnchorElMenuUser(null);
  };

  const { showConfirmModal } = useConfirmModal(() => {});

  const handleWarningButtonClick = async () => {
    showConfirmModal({
      title: tCms("warning"),
      content:
        tCms("locales-warning-text") +
        ` Strapi: [${localesStatus.strapiLocales.toString()}], Next.js: [${localesStatus.nextLocales.toString()}]`,
      acceptBtnText: tCms("ok"),
      icon: "triangle-exclamation-light",
      isCms: true,
      hideDenyBtn: true,
    });
  };

  return (
    <Box
      sx={{
        display: { xs: "none", sm: "flex" },
        justifyContent: "flex-end",
        flexGrow: 1,
      }}
    >
      {editMode && currentContext === CONTEXT_PAGE ? (
        <>
          <PbButton
            aria-label="media library"
            aria-controls="menu-appbar-media-library"
            aria-haspopup="true"
            className="cms-top-bar-btn"
            color="inherit"
            showOnlyIconBelowWidth={1450}
            startIcon={
              <PbIcon
                name="photo-film-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            onClick={() => openMediaManagerSelector()}
            title={tCms("topBar-manageMedia")}
          >
            {tCms("topBar-manageMedia")}
          </PbButton>
          <PbButton
            aria-label="page settings"
            aria-controls="menu-appbar-page-settings"
            aria-haspopup="true"
            className="cms-top-bar-btn"
            color="inherit"
            showOnlyIconBelowWidth={1450}
            startIcon={
              <PbIcon
                name="gear-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            onClick={() => setOpenSettings(true)}
            title={tCms("topBar-settings")}
          >
            {tCms("topBar-settings")}
          </PbButton>
          <CmsPageSettingsModal
            open={openSettings}
            onClose={() => setOpenSettings(false)}
          />
        </>
      ) : (
        <>
          <PbButton
            aria-label="manage content"
            aria-controls="menu-appbar-manage-content"
            aria-haspopup="true"
            showOnlyIconBelowWidth={1450}
            onClick={(event) => setAnchorElMenuManage(event.currentTarget)}
            className="cms-top-bar-btn"
            color="inherit"
            startIcon={
              <PbIcon
                name="list-ul-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
            title={tCms("manage")}
          >
            {tCms("manage")}
          </PbButton>
          <Menu
            sx={menuStyle}
            id="menu-appbar-manage-content"
            anchorEl={anchorElMenuManage}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            className="pb-cms"
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElMenuManage)}
            onClose={() => setAnchorElMenuManage(null)}
          >
            <MenuItem
              key={"manage-pages-link"}
              className={"cms-mui-paper"}
              onClick={() => {
                setAnchorElMenuManage(null);
                router.push("/cms/pages");
              }}
              sx={menuItemStyle}
            >
              <Typography sx={typographyStyle} textAlign="center">
                {tCms("topBar-managePages")}
              </Typography>
            </MenuItem>
            <MenuItem
              key={"manage-forms-link"}
              className={"cms-mui-paper"}
              onClick={() => {
                setAnchorElMenuManage(null);
                router.push("/cms/forms");
              }}
              sx={menuItemStyle}
            >
              <Typography sx={typographyStyle} textAlign="center">
                {tCms("topBar-manageForms")}
              </Typography>
            </MenuItem>
            <MenuItem
              key={"edit-navigations"}
              className={"cms-mui-paper"}
              onClick={() => {
                setAnchorElMenuManage(null);
                router.push("/cms/navigations/edit");
              }}
              sx={menuItemStyle}
            >
              <Typography sx={typographyStyle} textAlign="center">
                {tCms("topBar-manageNavigations")}
              </Typography>
            </MenuItem>
            <MenuItem
              key={"manage-categories"}
              className={"cms-mui-paper"}
              onClick={() => {
                setAnchorElMenuManage(null);
                router.push("/cms/categories");
              }}
              sx={menuItemStyle}
            >
              <Typography sx={typographyStyle} textAlign="center">
                {tCms("topBar-manageCategories")}
              </Typography>
            </MenuItem>
            <MenuItem
              key={"manage-pagetype-link"}
              className={"cms-mui-paper"}
              onClick={() => {
                setAnchorElMenuManage(null);
                router.push("/cms/pagetypes");
              }}
              sx={menuItemStyle}
            >
              <Typography sx={typographyStyle} textAlign="center">
                {tCms("topBar-managePageTypes")}
              </Typography>
            </MenuItem>
            <MenuItem
              key={"manage-media-manager"}
              className={"cms-mui-paper"}
              onClick={() => {
                setAnchorElMenuManage(null);
                openMediaManagerSelector();
              }}
              sx={menuItemStyle}
            >
              <Typography sx={typographyStyle} textAlign="center">
                {tCms("topBar-manageMedia")}
              </Typography>
            </MenuItem>
          </Menu>
          {currentContext !== CONTEXT_NAVIGATION &&
          currentContext !== CONTEXT_FORM &&
          currentContext !== CONTEXT_CATEGORY &&
          currentContext !== CONTEXT_PAGETYPE &&
          !editMode ? (
            <PbButton
              aria-label="add content"
              aria-controls="menu-appbar-add-content"
              aria-haspopup="true"
              showOnlyIconBelowWidth={1450}
              onClick={(event) => setAnchorElMenuAdd(event.currentTarget)}
              className="cms-top-bar-btn"
              color="inherit"
              startIcon={
                <PbIcon
                  name="plus-light"
                  width={20}
                  height={20}
                  className="svg-fill-white"
                />
              }
              title={tCms("add")}
            >
              {tCms("add")}
            </PbButton>
          ) : null}
        </>
      )}
      <Menu
        sx={menuStyle}
        id="menu-appbar-add-content"
        anchorEl={anchorElMenuAdd}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="pb-cms"
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElMenuAdd)}
        onClose={() => setAnchorElMenuAdd(null)}
      >
        {pageTypes &&
          pageTypes.map((pageType: PbPageType, index) => {
            return (
              <MenuItem
                key={pageType.id}
                className={"cms-mui-paper"}
                onClick={() => {
                  dispatch(showCreatePageModalAction(pageType.id));
                  setAnchorElMenuAdd(null);
                }}
                sx={menuItemStyle}
              >
                <div className="d-flex">
                  <span
                    style={{
                      marginTop: "-2px",
                      marginRight: "7px",
                    }}
                    title={
                      tCms("pageType") +
                      ": " +
                      (getPageTypeNameLocalized(
                        pageType,
                        process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE!
                      ) ?? "")
                    }
                  >
                    <PbIcon
                      width={15}
                      height={15}
                      name="table-layout-light"
                      style={{ fill: pageType.color ?? "white" }}
                    />
                  </span>
                  <Typography textAlign="center" sx={typographyStyle}>
                    {getPageTypeNameLocalized(
                      pageType,
                      process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE!
                    )}
                  </Typography>
                </div>
              </MenuItem>
            );
          })}
      </Menu>
      <PbButton
        aria-label="account of current user"
        aria-controls="menu-appbar-user"
        aria-haspopup="true"
        showOnlyIconBelowWidth={1700}
        onClick={(event) => setAnchorElMenuUser(event.currentTarget)}
        className="cms-top-bar-btn"
        color="inherit"
        startIcon={
          <PbIcon
            name="circle-user-light"
            width={20}
            height={20}
            className="svg-fill-white"
          />
        }
        title={cmsUserEmail}
      >
        {cmsUserEmail}
      </PbButton>
      <Menu
        sx={menuStyle}
        id="menu-appbar-user"
        anchorEl={anchorElMenuUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="pb-cms"
        open={Boolean(anchorElMenuUser)}
        onClose={() => setAnchorElMenuUser(null)}
      >
        <MenuItem
          key={"logout"}
          className={"cms-mui-paper"}
          onClick={() => handleLogout()}
          sx={menuItemStyle}
        >
          <Typography textAlign="center" sx={typographyStyle}>
            {tCms("logout")}
          </Typography>
        </MenuItem>
        <MenuItem
          key={"settings"}
          className={"cms-mui-paper"}
          onClick={() => setShowCmsUserSettingsModal(true)}
          sx={menuItemStyle}
        >
          <Typography textAlign="center" sx={typographyStyle}>
            {tCms("settings")}
          </Typography>
        </MenuItem>
        <MenuItem
          key={"about"}
          className={"cms-mui-paper"}
          onClick={() => setShowCmsAboutModal(true)}
          sx={menuItemStyle}
        >
          <Typography textAlign="center" sx={typographyStyle}>
            {tCms("version")}
          </Typography>
        </MenuItem>
      </Menu>
      <CmsLocaleSyncWarning>
        <PbButton
          className="cms-top-bar-btn mx-1"
          color="inherit"
          onClick={handleWarningButtonClick}
          startIcon={
            <PbIcon
              name="triangle-exclamation-light"
              width={20}
              height={20}
              style={{ fill: "red" }}
            />
          }
        />
      </CmsLocaleSyncWarning>
      <CmsLanguageSelector />
      <CmsPageCreateModal />
      <CmsUserSettingsModal
        open={showCmsUserSettingsModal}
        onClose={() => setShowCmsUserSettingsModal(false)}
      />
      <CmsAboutModal
        open={showCmsAboutModal}
        onClose={() => setShowCmsAboutModal(false)}
      />
    </Box>
  );
};

export default CmsTopBarRightControls;
