import { useAppSelector } from "@/store/store";
import { getDefaultLocale } from "@/utils/localizationUtil";
import React from "react";
import PbInput from "../input/pbInput/pbInput";
import ModalInputWrapper from "../util/modalInputWrapper";

interface LocalizedJsonFieldProps {
  /**
   * a strapi json field represented as object containing localized texts
   */
  jsonField: { [locale: string]: string };
  /**
   * Contains the locale e.g. "en" and the input value
   */
  onChange: (locale: string, value: string) => void;
  /**
   * Description for the default language text
   */
  defaultDescription: string;
  /**
   * Description for all other texts
   */
  localesDescription: string;
}

/**
 * Shows a strapi json field containing localized texts as a list of inputs to edit them
 */
export default function LocalizedJsonField(props: LocalizedJsonFieldProps) {
  const defaultLocale = getDefaultLocale();
  const nextLocales = useAppSelector(
    (state) => state.cmsGeneral.localesStatus.nextLocales
  );

  const onChange = (locale: string, value: string) => {
    props.onChange(locale, value);
  };

  return (
    <>
      <ModalInputWrapper
        label={defaultLocale.toUpperCase()}
        description={props.defaultDescription}
        style={{ paddingBottom: 45 }}
      >
        <PbInput
          withCmsStyle
          fullWidth
          label=""
          defaultValue={props.jsonField[defaultLocale]}
          onChange={(e) => onChange(defaultLocale, e.target.value)}
        />
      </ModalInputWrapper>
      {nextLocales.map((locale, index) => {
        if (locale !== defaultLocale) {
          return (
            <React.Fragment key={locale}>
              <ModalInputWrapper
                label={locale.toUpperCase()}
                description={props.localesDescription}
                style={{
                  paddingBottom: index < nextLocales.length - 2 ? 45 : 0,
                }}
              >
                <PbInput
                  withCmsStyle
                  fullWidth
                  label=""
                  defaultValue={props.jsonField[locale]}
                  onChange={(e) => onChange(locale, e.target.value)}
                />
              </ModalInputWrapper>
            </React.Fragment>
          );
        }
      })}
    </>
  );
}
