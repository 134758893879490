import ViewportPreviewStyles from "@/components/contentelements/wrapper/viewportPreviewWrapper";
import useStrSpaceX from "@/hooks/useStrSpaceX";
import { storeColorCss } from "@/services/ceSettings/ceSettingsService";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { lowerBreakpointWqhdPx } from "@/utils/util";

export default function NavigationDesktopItemsStyle() {
  const spaceX: number =
    useStrSpaceX(globalConfig?.navigation?.spaceX, undefined) ?? 0;
  const scalingFactorSpaceX: number =
    globalConfig?.layout.scalingfactorSpaceX.wqhd ?? 1;

  const calculateWQHDSpaceX = spaceX * scalingFactorSpaceX + "px";

  return (
    <>
      <style jsx global>{`
        .navigation-desktop-top-level-menu {
          margin: 0;
          padding-left: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          list-style-type: none;
          gap: 1rem;
        }

        .navigation-item {
          background-color: ${storeColorCss(
            globalConfig?.navigation.bgColorLinks
          )};
        }

        .navigation-desktop-top-level-menu > li {
          display: block;
        }

        .navigation-desktop-submenu-drawer.hidden {
          visibility: hidden;
          opacity: 0;
          transition-delay: 500ms;
        }

        .navigation-desktop-submenu-drawer {
          @media (min-width: ${lowerBreakpointWqhdPx()}) {
            padding-left: ${calculateWQHDSpaceX};
            padding-right: ${calculateWQHDSpaceX};
          }

          padding-left: ${spaceX}px;
          padding-right: ${spaceX}px;
          transition: visibility 0.2s linear, opacity 0.2s linear;
          opacity: 1;
          visibility: visible;

          position: absolute;
          display: block;
          width: 100%;
          bottom: 0;
          transform: translateY(100%);
          left: 0;
          z-index: 3;
          box-shadow: 0 1px 5px -3px black;
          background-color: ${storeColorCss(
            globalConfig?.navigation.bgColor,
            "var(--pb-white)"
          )};

          .navigation-desktop-submenu-drawer-content {
            margin: auto;
            display: grid;
            grid-template-columns: repeat(auto-fill, 300px);
            justify-content: space-between;

            .navigation-desktop-submenu-drawer-content-col {
              width: 300px;
              padding: 0 0 15px 0;
            }
          }

          .navigation-desktop-submenu-drawer-connector {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(-100%);
            width: 100%;
          }
        }

        .navigation-desktop-submenu-drawer {
          .sub-navigation-item {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .navigation-item .navigation-link.active,
        .sub-navigation-item .navigation-link.active {
          color: ${storeColorCss(
            globalConfig?.navigation.linkActiveColor,
            "unset"
          )};
        }
      `}</style>
      <ViewportPreviewStyles>
        <style>{`
          @container content-wrapper (min-width: ${lowerBreakpointWqhdPx()}) {
            .navigation-desktop-submenu-drawer {
              padding-left: ${calculateWQHDSpaceX} !important;
              padding-right: ${calculateWQHDSpaceX} !important;
            }
          }
        `}</style>
      </ViewportPreviewStyles>
    </>
  );
}
