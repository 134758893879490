import PbInput from "@/components/input/pbInput/pbInput";
import PbRichTextEditor from "@/components/input/pbRichTextEditor/pbRichTextEditor";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { EnabledListItemAttributesType } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { Listitem, StrapiUploadFile } from "@/types/strapi";
import { Col, Row } from "react-bootstrap";
import CmsContentImage from "../cmsContent/cmsContentImage";
import CmsLinkChooser from "../cmsLinkChooser/cmsLinkChooser";
import CmsDynamicListItemStyle from "./cmsDynamicListItemStyle";

interface CmsDynamicListItemProps {
  item: Listitem;
  position: number;
}

function CmsDynamicListItem(props: CmsDynamicListItemProps) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();

  const updateField = (field: string, value: any) => {
    dispatch(
      updateAttributeAction({
        attributePath: `dynamicList.editDynamicList.content[${props.position}].${field}`,
        value: value,
      })
    );
  };

  const enabledListItemAttributes = useAppSelector(
    (state) => state.cmsGeneral.dynamicListModal?.enabledListItemAttributes
  );

  const isEnabled = (fieldName: EnabledListItemAttributesType) => {
    return (
      enabledListItemAttributes.includes(fieldName) ||
      enabledListItemAttributes.length === 0
    );
  };

  const { richTextClassName, richtextEditorSettings } = useCesStrRichText(
    globalConfig?.footer?.richtextFooter
  );

  return (
    <>
      <div className={`cms-dynamic-list-item`}>
        <Row className="mb-3 pt-3">
          {isEnabled("title") ? (
            <Col>
              <ModalInputWrapper
                label={tCms("title")}
                description={tCms("dynamicListModalItem-titleDescription")}
              >
                <PbInput
                  id="dynamic-list-title"
                  fullWidth
                  defaultValue={props.item.title}
                  onChange={(e) => {
                    updateField("title", e.target.value);
                  }}
                />
              </ModalInputWrapper>
            </Col>
          ) : null}
          {isEnabled("richTextContent") ? (
            <Col>
              <ModalInputWrapper
                label={tCms("richtext")}
                description={tCms("dynamicListModalItem-richtextDescription")}
              >
                <PbRichTextEditor
                  id="dynamic-list-richtext"
                  className={richTextClassName}
                  settings={richtextEditorSettings}
                  onChange={(value: string) =>
                    updateField("richTextContent", value)
                  }
                  content={props.item.richTextContent}
                />
              </ModalInputWrapper>
            </Col>
          ) : null}
        </Row>
        <Row className="mb-3">
          {isEnabled("linkText") ? (
            <Col>
              <ModalInputWrapper
                label={tCms("linkText")}
                description={tCms("dynamicListModalItem-linkTextDescription")}
              >
                <PbInput
                  id="dynamic-list-link-text"
                  defaultValue={props.item.linkText}
                  onChange={(e) => updateField("linkText", e.target.value)}
                />
              </ModalInputWrapper>
            </Col>
          ) : null}
          {isEnabled("linkUrl") ? (
            <Col>
              <ModalInputWrapper
                label={tCms("dynamicList-link")}
                description={tCms(
                  "dynamicListModalItem-linkChooserDescription"
                )}
              >
                <CmsLinkChooser
                  label={tCms("linkUrl")}
                  url={props.item.linkUrl}
                  rel={props.item.linkRel}
                  target={props.item.linkTarget}
                  getParams={props.item.linkGetParams}
                  hrefLang={props.item.linkHrefLang}
                  anchor={props.item.linkAnchor}
                  onUrlChange={(url) => updateField("linkUrl", url)}
                  onGetParamsChange={(getParams) =>
                    updateField("linkGetParams", getParams)
                  }
                  onRelChange={(rel) => updateField("linkRel", rel)}
                  onHrefLangChange={(hrefLang) =>
                    updateField("linkHrefLang", hrefLang)
                  }
                  onTargetChange={(target) => updateField("linkTarget", target)}
                  onAnchorChange={(anchor) => updateField("linkAnchor", anchor)}
                />
              </ModalInputWrapper>
            </Col>
          ) : null}
        </Row>
        <Row>
          {isEnabled("img") ? (
            <Col>
              <ModalInputWrapper
                label={tCms("image")}
                description={tCms("mediaSelector-pleaseSelectImage")}
              >
                <CmsContentImage
                  height={156}
                  widthLimitSettings={150}
                  img={props.item.img}
                  isMobile={false}
                  onChange={(img?: StrapiUploadFile) =>
                    updateField("img", img ?? null)
                  }
                />
              </ModalInputWrapper>
            </Col>
          ) : null}
          {isEnabled("imgCaption") ? (
            <Col>
              <ModalInputWrapper
                label={tCms("imageHeader")}
                description={tCms(
                  "dynamicListModalItem-imageHeaderDescription"
                )}
              >
                <PbInput
                  id="dynamic-list-img-caption"
                  withCmsStyle
                  fullWidth
                  defaultValue={props.item.imgCaption}
                  onChange={(e) => updateField("imgCaption", e.target.value)}
                />
              </ModalInputWrapper>
            </Col>
          ) : null}
        </Row>
      </div>
      <CmsDynamicListItemStyle />
    </>
  );
}

export default CmsDynamicListItem;
