import PbAutoComplete from "@/components/input/pbAutoComplete/pbAutoComplete";
import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttributeMedia } from "@/types/strapi";
import { DEFAULT_MEDIA_CATEGORIES } from "@/utils/constants";
import { TextField } from "@mui/material";

export interface CmsPropertyAttributeEditMediaProps {
  attribute: CmsPropertyAttributeMedia | undefined;
  onChange: (attributeName: string, value: any) => void;
}

export default function CmsPropertyAttributeEditMedia(
  props: CmsPropertyAttributeEditMediaProps
) {
  const tCms = useCmsTranslation();

  return (
    <ModalInputWrapper
      label={tCms("allowedTypes")}
      description={tCms("allowedTypesDescription")}
    >
      <PbAutoComplete
        disablePortal={false}
        disableCloseOnSelect
        withCmsStyle
        fullWidth
        multiple={true}
        options={DEFAULT_MEDIA_CATEGORIES}
        defaultValue={props.attribute?.allowedTypes ?? []}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={
              props.attribute?.allowedTypes ? "" : tCms("choose-option")
            }
            margin="none"
          />
        )}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <div className="mt-1">
                <PbCheckBox
                  value={selected as boolean}
                  isSlider={false}
                  checkBoxFontSize={18}
                />
              </div>
              {option}
            </li>
          );
        }}
        onChange={(e, value) => {
          props.onChange("allowedTypes", value);
        }}
      />
    </ModalInputWrapper>
  );
}
