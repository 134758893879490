import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttributeEnum } from "@/types/strapi";

export interface CmsPropertyAttributeEditEnumProps {
  attribute: CmsPropertyAttributeEnum | undefined;
  onChange: (attributeName: string, value: any) => void;
}

export default function CmsPropertyAttributeEditEnum(
  props: CmsPropertyAttributeEditEnumProps
) {
  const tCms = useCmsTranslation();
  return (
    <ModalInputWrapper
      label={tCms("defaultValue")}
      description={
        tCms("defaultValueDescription") + " " + tCms("dropdownOptionsDefault")
      }
    >
      <PbInput
        withCmsStyle
        fullWidth
        placeholder={tCms("defaultValue")}
        defaultValue={props.attribute?.default}
        onChange={(e) => {
          props.onChange("default", e.target.value);
        }}
      />
    </ModalInputWrapper>
  );
}
