import { resetEditDataAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import {
  resetEditModeAction,
  setCurrentContextAction,
  setCurrentPropsPage,
} from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { PbPage } from "@/types/content-elements";
import {
  CONTEXT_CATEGORY,
  CONTEXT_FORM,
  CONTEXT_NAVIGATION,
  CONTEXT_PAGE,
  CONTEXT_PAGETYPE,
} from "@/utils/constants";
import { useRouter } from "next/router";
import { useEffect } from "react";

/**
 * hook that sets the current cms context
 */
export default function useCmsTopBarEffects(page?: PbPage | null) {
  const dispatch = useAppDispatch();
  const currentContext = useAppSelector(
    (state) => state.cmsGeneral.currentContext
  );
  const currentPropsPage = useAppSelector(
    (state) => state.cmsGeneral.currentPropsPage
  );
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const router = useRouter();

  /**
   * this useEffect takes care of setting the correct currentContext state
   * after navigating / redirection to another page (after page url create/update/delete
   * or pages select change)
   * This useEffect also runs if you manually navigate to other pages or
   * refresh the page.
   * */
  useEffect(() => {
    let newContext = null;
    if (
      router.pathname === "/" ||
      router.pathname === "/index" ||
      router.pathname === "/[...url]" ||
      router.pathname === "/cms/pages"
    ) {
      newContext = CONTEXT_PAGE;
    }
    if (
      router.pathname === "/cms/forms/edit/[formId]" ||
      router.pathname === "/cms/forms/create" ||
      router.pathname === "/cms/forms"
    ) {
      newContext = CONTEXT_FORM;
    }
    if (
      router.pathname === "/cms/navigations/edit" ||
      router.pathname === "/cms/navigations/create" ||
      router.pathname === "/cms/navigations"
    ) {
      newContext = CONTEXT_NAVIGATION;
    }
    if (router.pathname === "/cms/categories") {
      newContext = CONTEXT_CATEGORY;
    }
    if (
      router.pathname === "/cms/pagetypes/edit/[pageTypeId]" ||
      router.pathname === "/cms/pagetypes"
    ) {
      newContext = CONTEXT_PAGETYPE;
    }
    if (currentContext !== newContext) {
      dispatch(setCurrentContextAction(newContext));
    }
  }, [router, dispatch, currentContext]);

  useEffect(() => {
    if (page) {
      dispatch(setCurrentPropsPage(page));
    } else {
      dispatch(setCurrentPropsPage(null));
    }
  }, [page, dispatch]);

  // force leave edit mode if the user is on another page than the editPage state
  useEffect(() => {
    if (editMode && currentContext === null) {
      global.log.debug("force leave edit-mode");
      dispatch(resetEditDataAction());
      dispatch(resetEditModeAction());
    }
  }, [currentContext, editMode, dispatch]);
}
