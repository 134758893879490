import PbButton from "@/components/input/pbButton/pbButton";
import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import {
  addToArrayAction,
  moveInArrayAction,
  removeFromArrayAction,
  updateAttributeAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppDispatch } from "@/store/store";
import {
  CmsPropertyAttributeEnum,
  CmsPropertyAttributeEnumOption,
} from "@/types/strapi";
import { DEBOUNCE_DEFAULT_TIME } from "@/utils/constants";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CmsFormFieldOptionInput from "../../cmsForm/cmsFormFieldOptionInput";
import CmsGeneralConfigFrame from "../../cmsGeneralConfigFrame/cmsGeneralConfigFrame";

export interface CmsPropertyAttributeEditEnumOptionsProps {
  attribute: CmsPropertyAttributeEnum | undefined;
  editedAttributeGroupIndex: number | null;
  editedAttributeIndex: number | null;
  editorPropertiesAttributePath: string | undefined;
}

export default function CmsPropertyAttributeEditEnumOptions(
  props: CmsPropertyAttributeEditEnumOptionsProps
) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const addedFirstDropdownItem = useRef(false);
  const [listRenderKey, setListRenderKey] = useState<number>(
    new Date().getTime()
  );

  const [differentNameAndValue, setDifferentNameAndValue] = useState(() => {
    let hasDifferentNamesAndValues = false;
    props.attribute?.options?.forEach(
      (element: CmsPropertyAttributeEnumOption) => {
        if (element.name !== element.value) {
          hasDifferentNamesAndValues = true;
        }
      }
    );
    return hasDifferentNamesAndValues;
  });

  const [toDeleteOptionIndex, setToDeleteOptionIndex] = useState<number | null>(
    null
  );
  const { showConfirmModal } = useConfirmModal(() => {
    if (toDeleteOptionIndex !== null) {
      dispatch(
        removeFromArrayAction({
          attributePath:
            `${props.editorPropertiesAttributePath}[${props.editedAttributeGroupIndex}]` +
            `.attributes[${props.editedAttributeIndex}].options`,
          position: toDeleteOptionIndex,
        })
      );
      setListRenderKey(new Date().getTime());
    }
  });

  const addNewOption = useCallback(() => {
    const newOption = {
      name: "",
      value: "",
    };
    dispatch(
      addToArrayAction({
        attributePath:
          `${props.editorPropertiesAttributePath}[${props.editedAttributeGroupIndex}]` +
          `.attributes[${props.editedAttributeIndex}].options`,
        value: newOption,
      })
    );
  }, [
    dispatch,
    props.editorPropertiesAttributePath,
    props.editedAttributeGroupIndex,
    props.editedAttributeIndex,
  ]);

  // pre-load an empty nested content-item
  useEffect(() => {
    if (
      addedFirstDropdownItem.current === false &&
      props.attribute?.options?.length === 0
    ) {
      addedFirstDropdownItem.current = true;
      addNewOption();
    }
  }, [props.attribute?.options?.length, addNewOption]);

  const onDeleteFormFieldOption = (index: number) => {
    setToDeleteOptionIndex(index);
    showConfirmModal({
      title: tCms("form-deleteOptionConfirmation"),
      content: tCms("form-deleteOptionConfirmationLong"),
      acceptBtnText: tCms("yes"),
      denyBtnText: tCms("no"),
      icon: "triangle-exclamation-light",
    });
  };

  const onEditOption = (
    field: string,
    value: string,
    position: number,
    updateBoth?: boolean
  ) => {
    dispatch(
      updateAttributeAction({
        attributePath:
          `${props.editorPropertiesAttributePath}[${props.editedAttributeGroupIndex}]` +
          `.attributes[${props.editedAttributeIndex}].options[${position}][${field}]`,
        value: value,
        debounce: DEBOUNCE_DEFAULT_TIME,
      })
    );
    if (updateBoth) {
      dispatch(
        updateAttributeAction({
          attributePath:
            `${props.editorPropertiesAttributePath}[${props.editedAttributeGroupIndex}]` +
            `.attributes[${props.editedAttributeIndex}].options[${position}].value`,
          value: value,
          debounce: DEBOUNCE_DEFAULT_TIME,
        })
      );
    }
  };

  const toggleDifferentNameAndValue = () => {
    setDifferentNameAndValue((prevState) => {
      if (prevState) {
        props.attribute?.options?.forEach(
          (element: CmsPropertyAttributeEnumOption, index: number) => {
            onEditOption("name", element.name, index, prevState);
          }
        );
      }
      return !prevState;
    });
  };

  return (
    <>
      <Row>
        <Col xs={7}>
          <label className="cms-modal-label">{tCms("dropdownOptions")}</label>
        </Col>
      </Row>
      <Row className="pb-4">
        <Col xs={7}>
          <div className="cms-modal-text">
            {tCms("form-fieldDropdownValuesDescription")}
          </div>
        </Col>
        <Col xs={5}>
          <PbCheckBox
            isSlider={true}
            value={differentNameAndValue}
            onChange={(e) => toggleDifferentNameAndValue()}
            fullHeight
          />
        </Col>
      </Row>
      <Row className="form-field-options-editor">
        <Col xs={12} key={"" + listRenderKey}>
          {props.attribute?.options?.map(
            (element: CmsPropertyAttributeEnumOption, index: number) => {
              return (
                <React.Fragment key={index}>
                  <hr />
                  <CmsGeneralConfigFrame
                    title={tCms("form-optionAndNumber", {
                      index: "" + (index + 1),
                    })}
                    moveUp={() => {
                      dispatch(
                        moveInArrayAction({
                          attributePath:
                            `${props.editorPropertiesAttributePath}[${props.editedAttributeGroupIndex}]` +
                            `.attributes[${props.editedAttributeIndex}].options[${index}]`,
                          direction: "up",
                        })
                      );
                      setListRenderKey(new Date().getTime());
                    }}
                    moveDown={() => {
                      dispatch(
                        moveInArrayAction({
                          attributePath:
                            `${props.editorPropertiesAttributePath}[${props.editedAttributeGroupIndex}]` +
                            `.attributes[${props.editedAttributeIndex}].options[${index}]`,
                          direction: "down",
                        })
                      );
                      setListRenderKey(new Date().getTime());
                    }}
                    onDelete={() => onDeleteFormFieldOption(index)}
                    position={index}
                    arrayLength={props.attribute?.options?.length}
                    frameOnHover
                    buttonsRight
                    showFrameWithoutEditMode={true}
                  >
                    <CmsFormFieldOptionInput
                      name={element.name}
                      value={element.value}
                      onEditFormFieldOption={onEditOption}
                      index={index}
                      showValueField={differentNameAndValue}
                    />
                  </CmsGeneralConfigFrame>
                </React.Fragment>
              );
            }
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="py-5 text-center">
          <PbButton
            className="cms-btn-add-content"
            onClick={() => addNewOption()}
            title={tCms("form-addOption")}
            startIcon={
              <PbIcon
                name="plus-light"
                width={20}
                height={20}
                className="svg-fill-white"
              />
            }
          >
            {tCms("form-addNewOption")}
          </PbButton>
        </Col>
      </Row>
      <hr />

      <style jsx>{`
        :global(.form-field-options-editor) {
          max-height: 430px;
          overflow: auto;
        }

        :global(.form-field-options-editor .general-config-frame) {
          margin-top: 0;
          margin-bottom: 0;
        }
      `}</style>
    </>
  );
}
