import PbCheckBox from "@/components/input/pbCheckbox/pbCheckbox";
import PbInput from "@/components/input/pbInput/pbInput";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { CmsPropertyAttributeRelation } from "@/types/strapi";

export interface CmsPropertyAttributeEditRelationProps {
  attribute: CmsPropertyAttributeRelation | undefined;
  onChange: (attributeName: string, value: any) => void;
}

export default function CmsPropertyAttributeEditRelation(
  props: CmsPropertyAttributeEditRelationProps
) {
  const tCms = useCmsTranslation();

  return (
    <>
      <ModalInputWrapper
        label={tCms("propertyAttribute-model")}
        description={tCms("propertyAttribute-modelDesc")}
      >
        <PbInput
          withCmsStyle
          fullWidth
          placeholder={"api::article.article"}
          defaultValue={props.attribute?.model}
          onChange={(e) => {
            props.onChange("model", e.target.value);
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("propertyAttribute-labelAttribute")}
        description={tCms("propertyAttribute-labelAttributeDesc")}
      >
        <PbInput
          withCmsStyle
          fullWidth
          placeholder={"name"}
          defaultValue={props.attribute?.labelAttribute}
          onChange={(e) => {
            props.onChange("labelAttribute", e.target.value);
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("propertyAttribute-valueAttribute")}
        description={tCms("propertyAttribute-valueAttributeDesc")}
      >
        <PbInput
          withCmsStyle
          fullWidth
          placeholder={"id"}
          defaultValue={props.attribute?.valueAttribute}
          onChange={(e) => {
            props.onChange("valueAttribute", e.target.value);
          }}
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("propertyAttribute-localizedOptions")}
        description={tCms("propertyAttribute-localizedOptionsDesc")}
      >
        <PbCheckBox
          withoutColon
          value={!!props.attribute?.localizedOptions}
          isSlider={true}
          onChange={() => {
            props.onChange(
              "localizedOptions",
              !!!props.attribute?.localizedOptions
            );
          }}
          fullHeight
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("propertyAttribute-multiple")}
        description={tCms("propertyAttribute-multipleDesc")}
      >
        <PbCheckBox
          withoutColon
          value={!!props.attribute?.multiple}
          isSlider={true}
          onChange={() => {
            props.onChange("multiple", !!!props.attribute?.multiple);
          }}
          fullHeight
        />
      </ModalInputWrapper>
      <ModalInputWrapper
        label={tCms("propertyAttribute-populated")}
        description={tCms("propertyAttribute-populatedDesc")}
      >
        <PbCheckBox
          withoutColon
          value={!!props.attribute?.populated}
          isSlider={true}
          onChange={() => {
            props.onChange("populated", !!!props.attribute?.populated);
          }}
          fullHeight
        />
      </ModalInputWrapper>
    </>
  );
}
