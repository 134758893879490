const CmsTopBarStyle = (): JSX.Element => {
  return (
    <style jsx global>{`
      /* CMS-Styles (& Bootstrap Overrides) */
      /* 
      --pb-black: Use this for things that should be black. 
      --pb-white: Use this for things that should be white. 

      CMS-only:
      --pb-cms-color: Use this as color if you want to color something in the "CMS-Blue". 

      --pb-cms-variable-black: Use this as color if you want to color something as black by default, but it is allowed to change (maybe in the future!) to white.
      --pb-cms-variable-white: Use this as color if you want to color something as white by default, but it is allowed to change (maybe in the future!) to white.
      Note: Use these variables only in CMS-Componens!

      */
      :root {
        --pb-cms-color: #198fd9;
        --pb-cms-text-color: #333333;
        --pb-cms-color-hover: #1565c0;
        --pb-cms-variable-black: #000000;
        --pb-cms-variable-white: #ffffff;
        --pb-cms-font: "Roboto";
        --pb-cms-font-size: 1.4rem;
        --pb-cms-font-weight: 400;
        --pb-cms-line-height: 1.2;
        --pb-cms-modal-title-fontSize: 24px;
        --pb-cms-modal-text-fontSize: 12px;
        --pb-cms-modal-label-fontSize: 14px;
        --pb-cms-text-shadow: -1px -1px 0 var(--pb-cms-variable-black),
          1px -1px 0 var(--pb-cms-variable-black),
          -1px 1px 0 var(--pb-cms-variable-black),
          1px 1px 0 var(--pb-cms-variable-black);

        --bs-body-font-family: var(--pb-cms-font);
        --bs-body-font-size: var(--pb-cms-font-size);
        --bs-body-font-weight: var(--pb-cms-font-weight);
        --bs-body-line-height: var(--pb-cms-line-height);
      }
      /* TBD: Dark/Light theme: */
      /* We currently don't support a real dark theme. :( However we try to minimize issues that you'll face when using dark(er) bg colors. (by using configurable colors, using white as background-Color...) */
      .dark-theme {
        --pb-cms-variable-black: #ffffff;
        --pb-cms-variable-white: #000000;
      }

      /* CMS Styling */
      .am-pabu .pb-cms,
      .am-pabu .pb-cms p,
      .am-pabu .pb-cms h1,
      .am-pabu .pb-cms h2,
      .am-pabu .pb-cms h3,
      .am-pabu .pb-cms h4,
      .am-pabu .pb-cms h5,
      .am-pabu .pb-cms h6 {
        font-family: var(--pb-cms-font);
        font-size: var(--pb-cms-font-size);
        font-weight: var(--pb-cms-font-weight);
        line-height: var(--pb-cms-line-height);
        color: var(--pb-black);
        letter-spacing: normal;
        text-transform: none;
      }

      /* "Manage..."- Pages (cms-page) */
      /* Styling for CMS-only Pages (overwrites main-backgroundColor) */
      main.main {
        background-color: var(--pb-white) !important;
      }

      .cms-page h1 {
        font-family: var(--pb-cms-font) !important;
        font-size: calc(var(--pb-cms-font-size) * 2) !important;
        font-weight: var(--pb-cms-font-weight) !important;
        line-height: var(--pb-cms-line-height) !important;
        color: var(--pb-black) !important;
      }

      /* Styling of CMS-only Page */
      @media (min-width: 1200px) {
        .cms-page {
          max-width: 80% !important;
        }
      }

      .cms-placeholder-text {
        color: var(--pb-cms-color);
      }

      .add-content-zone .btn-text {
        color: var(--pb-cms-color);
      }

      .add-content-zone .btn-icon svg {
        fill: var(--pb-cms-color);
      }

      /* accordion, carousel, image-ticker, gallery add button, quickLinks */
      .pb-accordion-add button svg {
        fill: var(--pb-cms-color);
      }
      .pb-generic-nested-content-add {
        fill: var(--pb-cms-color);
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          font-size: 2.4rem;
          font-weight: 400;
          margin: 4rem;
          border: 4px dashed var(--pb-cms-color);
          height: 150px;
          width: 100%;
          background-color: unset;
        }
      }

      .placeholder-footer p,
      .social-media-placeholder {
        color: var(--pb-cms-color) !important;
      }

      .general-config-frame .general-config-frame {
        margin-top: 0;
        margin-bottom: 0;
      }

      /* Modal Styling */
      .cms-modal {
        .MuiDialogTitle-root {
          font-family: var(--pb-cms-font);
          font-size: var(--pb-cms-modal-title-fontSize);
          font-weight: var(--pb-cms-font-weight);
          line-height: var(--pb-cms-line-height);
          color: var(--pb-cms-text-color);
          letter-spacing: normal;
          text-transform: none;
          padding: 30px;
          border-bottom: 1px solid #00000040;
        }

        .modal-close {
          position: absolute;
          padding: 0;
          top: 30px;
          right: 30px;
          svg {
            font-size: 30px;
            fill: var(--pb-cms-text-color);
          }
        }

        .cms-modal-label {
          font-family: var(--pb-cms-font);
          font-size: var(--pb-cms-modal-label-fontSize);
          font-weight: bold;
          line-height: var(--pb-cms-line-height);
          color: var(--pb-cms-text-color);
          letter-spacing: normal;
          text-transform: none;
          padding-left: 2px;
          padding-bottom: 1rem;
        }

        &.with-tabs .cms-modal-label {
          padding-left: 0px;
        }

        .without-config-frame .cms-modal-label {
          padding-left: 0px;
        }

        .cms-modal-input-wrapper-container > * {
          padding-bottom: 45px;
        }

        .cms-modal-input-wrapper-container > *:last-child {
          padding-bottom: 0px;
        }

        .cms-modal-text {
          font-family: var(--pb-cms-font);
          font-size: var(--pb-cms-modal-text-fontSize);
          font-weight: var(--pb-cms-font-weight);
          line-height: var(--pb-cms-line-height);
          color: var(--pb-cms-text-color);
          letter-spacing: normal;
          text-transform: none;
          padding-top: 0px;
          padding-left: 2px;
          padding-right: 2rem;
        }

        &.with-tabs .cms-modal-text {
          padding-left: 0px;
        }

        .cms-modal-input {
          padding-right: 2px;
          padding-top: 1px;
        }

        &.with-tabs .cms-modal-input {
          padding-right: 0px;
        }

        .MuiDialogContent-root,
        .MuiTabPanel-root {
          padding: 30px;
        }

        &.with-tabs .MuiDialogContent-root {
          padding: 0px;
        }

        .MuiDialogActions-root {
          border-top: 1px solid #00000040;
          padding: 20px 30px;
          justify-content: center;

          button {
            min-width: 130px;
          }
        }
      }

      .cms-icon-bg {
        background-color: #e1e1e1;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        justify-content: center;
        display: flex;
        margin: auto;

        svg {
          margin: auto;
        }
      }
    `}</style>
  );
};

export default CmsTopBarStyle;
