import { Middleware } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";

const debounceMiddleware = (): Middleware => {
  const timers: Record<string, NodeJS.Timeout> = {};

  return (store) => (next) => (action) => {
    if (action?.payload?.debounce) {
      if (timers[action.type + action.payload.attributePath]) {
        clearTimeout(timers[action.type + action.payload.attributePath]);
      }
      timers[action.type + action.payload.attributePath] = setTimeout(() => {
        delete timers[action.type + action.payload.attributePath];
        next(action);
      }, action.payload.debounce);
    } else {
      next(action);
    }
  };
};

const loggerMiddleware = [
  createLogger({
    duration: true,
    timestamp: false,
    collapsed: true,
    diff: true,
    colors: {
      title: () => "#139BFE",
      prevState: () => "#1C5FAF",
      action: () => "#149945",
      nextState: () => "#A47104",
      error: () => "#ff0005",
    },
    predicate: () => typeof window !== "undefined",
  }),
];

export { debounceMiddleware, loggerMiddleware };
