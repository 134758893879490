import { CmsGeneralConfigFrameStyle } from "@/components/cms/cmsGeneralConfigFrame/cmsGeneralConfigFrameStyle";
import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import { useAppSelector } from "@/store/store";
import clsx from "clsx";
import { ReactElement, ReactNode, useEffect, useRef } from "react";

export interface CmsGeneralConfigFrameProps {
  /**
   * Element which is render inside the config frame
   */
  children: ReactNode;
  /**
   * The title of the config frame
   */
  title?: string;
  /**
   * Optional callback function that's called when clicking the open content edit modal button
   */
  openContentEditModal?: Function;
  /**
   * Optional callback function that's called when clicking the open config modal button
   */
  openConfigModal?: Function;
  /**
   * Optional callback function that's called when clicking the delete button
   */
  onDelete?: Function;
  /**
   * Optional callback function that's called when clicking the move up button
   */
  moveUp?: Function;
  /**
   * Optional callback function that's called when clicking the move down button
   */
  moveDown?: Function;
  /**
   * Position in the content array
   */
  position?: number;
  /**
   * Length of the content array
   */
  arrayLength?: number;
  /**
   * Render frame on hover
   */
  frameOnHover?: boolean;
  /**
   * Render buttons right instead of left
   */
  buttonsRight?: boolean;
  /**
   * Title of the config button
   */
  configButtonTitle?: string;
  /**
   * Displays frame when not in edit mode
   */
  showFrameWithoutEditMode?: boolean;
  /**
   * Render the config frames title smaller
   */
  smallTitle?: boolean;
  /**
   * Optional callback function that's called when clicking the clone button
   */
  cloneContentElement?: Function;
  /**
   * Optional callback function that's called when clicking the add button
   */
  onAddBtnClick?: Function;
  /**
   * In which direction to move on button click
   */
  moveDirection?: "horizontal" | "vertical";

  /**
   * if true the element will be focused upon loading
   */
  initialFocus?: boolean;

  /**
   * hides the frame if true
   */
  hideFrame?: boolean;
}

export default function CmsGeneralConfigFrame(
  props: CmsGeneralConfigFrameProps
) {
  const tCms = useCmsTranslation();
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const configFrameRef = useRef<HTMLDivElement>(null);

  const childrenAsReactElement = props.children as ReactElement<any, any>;
  const childrenName = (childrenAsReactElement.type as Function).name;

  useEffect(() => {
    if (props.initialFocus && configFrameRef.current) {
      configFrameRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.initialFocus]);

  if ((!editView && !props.showFrameWithoutEditMode) || props.hideFrame) {
    return props.children;
  }

  return (
    <>
      <div
        ref={configFrameRef}
        className={clsx(
          props.frameOnHover ? "frame-on-hover" : "frame",
          childrenName === "Navbar" && "config-frame-mobile-position-navbar",
          "general-config-frame"
        )}
      >
        <div className={"general-config-frame-bar"}>
          <div className="left-controls">
            {props.buttonsRight ? null : (
              <>
                {props.moveUp &&
                  (props.moveDirection &&
                  props.moveDirection === "horizontal" ? (
                    <PbButton
                      className="cms-config-frame-btn"
                      disabled={props.position === 0}
                      onClick={() => props.moveUp && props.moveUp()}
                      startIcon={
                        <PbIcon
                          name="angle-left-light"
                          className="svg-fill-white"
                          width={15}
                          height={15}
                        />
                      }
                      title={tCms("moveLeft")}
                    />
                  ) : (
                    <PbButton
                      className="cms-config-frame-btn"
                      disabled={props.position === 0}
                      onClick={() => props.moveUp && props.moveUp()}
                      startIcon={
                        <PbIcon
                          name="angle-up-light"
                          className="svg-fill-white"
                          width={15}
                          height={15}
                        />
                      }
                      title={tCms("moveUp")}
                    />
                  ))}
                {props.moveDown &&
                  (props.moveDirection &&
                  props.moveDirection === "horizontal" ? (
                    <PbButton
                      className="cms-config-frame-btn"
                      disabled={
                        props.position ===
                        (props.arrayLength ? props.arrayLength : 0) - 1
                      }
                      onClick={() => props.moveDown && props.moveDown()}
                      startIcon={
                        <PbIcon
                          name="angle-right-light"
                          className="svg-fill-white"
                          width={15}
                          height={15}
                        />
                      }
                      title={tCms("moveRight")}
                    />
                  ) : (
                    <PbButton
                      className="cms-config-frame-btn"
                      disabled={
                        props.position ===
                        (props.arrayLength ? props.arrayLength : 0) - 1
                      }
                      onClick={() => props.moveDown && props.moveDown()}
                      startIcon={
                        <PbIcon
                          name="angle-down-light"
                          className="svg-fill-white"
                          width={15}
                          height={15}
                        />
                      }
                      title={tCms("moveDown")}
                    />
                  ))}
              </>
            )}
          </div>
          {props.title && (
            <div className={`title ${props.smallTitle ? `small-title ` : ``}`}>
              {props.title}
            </div>
          )}

          <div className="right-controls">
            {props.buttonsRight ? (
              <>
                {props.moveUp && (
                  <PbButton
                    className="cms-config-frame-btn"
                    disabled={props.position === 0}
                    onClick={() => props.moveUp && props.moveUp()}
                    startIcon={
                      <PbIcon
                        name="angle-up-light"
                        className="svg-fill-white"
                        width={15}
                        height={15}
                      />
                    }
                    title={tCms("moveUp")}
                  />
                )}
                {props.moveDown && (
                  <PbButton
                    className="cms-config-frame-btn"
                    disabled={
                      props.position ===
                      (props.arrayLength ? props.arrayLength : 0) - 1
                    }
                    onClick={() => props.moveDown && props.moveDown()}
                    startIcon={
                      <PbIcon
                        name="angle-down-light"
                        className="svg-fill-white"
                        width={15}
                        height={15}
                      />
                    }
                    title={tCms("moveDown")}
                  />
                )}
              </>
            ) : null}
            {props.cloneContentElement && (
              <PbButton
                className="cms-config-frame-btn"
                startIcon={
                  <PbIcon
                    name="clone-light"
                    className="svg-fill-white"
                    width={15}
                    height={15}
                  />
                }
                onClick={() =>
                  props.cloneContentElement && props.cloneContentElement()
                }
                title={tCms("copy")}
              />
            )}
            {props.openContentEditModal && (
              <PbButton
                className="cms-config-frame-btn"
                onClick={() =>
                  props.openContentEditModal && props.openContentEditModal()
                }
                startIcon={
                  <PbIcon
                    name="pen-light"
                    width={15}
                    height={15}
                    className="svg-fill-white"
                  />
                }
                title={props.configButtonTitle || tCms("settings")}
              />
            )}
            {props.onDelete && (
              <PbButton
                className="cms-config-frame-btn"
                onClick={() => props.onDelete && props.onDelete()}
                startIcon={
                  <PbIcon
                    name="trash-light"
                    className={"svg-fill-white"}
                    width={15}
                    height={15}
                  />
                }
                title={tCms("deleteElement")}
              />
            )}
            {props.onAddBtnClick && (
              <PbButton
                className="cms-config-frame-btn"
                onClick={() => props.onAddBtnClick && props.onAddBtnClick()}
                startIcon={
                  <PbIcon
                    name="plus-light"
                    className="svg-fill-white"
                    width={15}
                    height={15}
                  />
                }
                title={tCms("add")}
              />
            )}
            {props.openConfigModal && (
              <PbButton
                className="cms-config-frame-btn"
                onClick={() => props.openConfigModal && props.openConfigModal()}
                startIcon={
                  <PbIcon
                    name="gear-light"
                    className="svg-fill-white"
                    width={15}
                    height={15}
                  />
                }
                title={tCms("settings")}
              />
            )}
          </div>
        </div>
        {props.children}
      </div>
      <CmsGeneralConfigFrameStyle />
    </>
  );
}
